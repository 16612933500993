import Header from "../Common/Header";
import {  useState } from "react";
import ContactList from "./ContactList";
import KeenSlider from "../Images/KeenSlider";
import { InputComponent } from "../Common/InputComponent";
import { Tabs, TabsCB } from "../Common/Tabs";
import WebsiteList from "./Websites/WebsiteList";

import { Edit, ScaleOutlined } from "@mui/icons-material";
import { Save } from "@mui/icons-material";
import { mobileAndTabletCheck } from "../Search/Deck";
import { useNavigate } from "react-router-dom";
import axios from 'axios'

import ImageUploadGallery from "../Common/Uploader/ImageUploadGallery";
import AvailableGroupList from "./AvailableGroups/AvailableGroupList";


import { Work, LocalShipping, CheckCircle } from "@mui/icons-material";

import { ViewInAr as CubeIcon, Bolt as LightningBoltIcon } from "@mui/icons-material";
import { Edit as PencilIcon, Science as BeakerIcon } from "@mui/icons-material"


import OpinionList from "../Common/OpinionList";

import JournalSplash from "../Common/Journals/JournalSplash";
import { Button, IconButton } from "@mui/material";

const integerFields = [
  "SizeMin",
  "SizeMax",
  "EstFreeRent",
  "TotalSize",
  "Floor",
  "TermAvailable",
  "GradeDoors",
  "DockDoors",
  "PowerAmps",
  "PowerVolts",
  "Sinks",
  "Hoods",
  "OfficeCeilingHeight",
  "WarehouseShellClearHeight",
  "CurrentCapacity",
  "HypotheticalCapacity",
  "ColumnSpacing",
  "LabCeilingHeight",
];

const currencyFields = [
  "AskRate",
  "Expenses",
  "AskTI",
  "EstOutOfPocket",
  "InitialRent",
  "ImprovementAllowancePerFoot",
  "AmortizedImprovementAllowancePerFoot",
];

const commaFields = ["TotalSize", "SizeMin", "SizeMax", "LeasedSpace"];

const percentFields = [
  "AskIncreases",
  "PercentOffice",
  "PercentLab",
  "PercentWarehouse",
  "PercentRaisedFloor",
  "LoadFactor",
  "ContractedEscalations",
];

const textAreaFields = ["Notes"];

const dateFields = [
  "DateListed",
  "DateAvailable",
  "DateConstructionComplete",
  "DateOccupancy",
  "ExecutionDate",
  "CommencementDate",
];

const optionFields = [
  {
    field: "PropertyType",
    options: [
      "Life Science",
      "Life Science or Industrial",
      "Life Science or Office",
      "Life Science, R&D, or Office",
      "Life Science, R&D, or Industrial",
      "GMP",
      "Industrial",
      "R&D",
      "R&D or Office",
      "Office",
      "Retail",
      "Other",
    ],
  },
  {
    field: "NewDealOrRenewal",
    options: ["New Deal", "Renewal", "Renewal Downsize", "Renewal Expansion"],
  },
  {
    field: "Furniture",
    options: ["None", "Partial", "Plug and Play", "New", "Available", "Other"],
  },
  {
    field: "FreightLegal",
    options: ["Yes", "No", "Limited", "Potential", "Other"],
  },
  {
    field: "ExposedCeilings",
    options: ["None", "Partial", "Yes", "Potential", "Other"],
  },
  {
    field: "Condition",
    options: [
      "Existing Lab",
      "Existing Office",
      "Fully Built",
      "Shell",
      "Under Construction",
      "Planned",
      "Other",
    ],
  },
  {
    field: "Status",
    options: [
      "Available",
      "LOI Activity",
      "Encumbered",
      "LOI Signed",
      "Lease Out",
      "Lease Comp",
      "Off Market",
      "Other",
    ],
  },
  {
    field: "SubleaseOrDirect",
    options: ["Sublease", "Direct", "Sublease and Direct", "Other"],
  },
  {
    field: "RentStructure",
    options: ["NNN", "IG", "FSG", "Other"],
  },
  {
    field: "JanitorialResponsibility",
    options: ["Landlord", "Tenant", "Sublandlord", "Subtenant", "Other"],
  },
  {
    field: "MaintenanceResponsibility",
    options: ["Landlord", "Tenant", "Sublandlord", "Subtenant", "Other"],
  },
  {
    field: "LabEquipment",
    options: ["Freezers, Biosafety Cabinets, Glassware", "None", "Other"],
  },
  {
    field: "ConstructionStage",
    options: ["Planned", "Under Construction", "Complete"],
  },
  {
    field: "AVEquipment",
    options: ["TVs, phones, montiors", "None", "Other"],
  },
  {
    field: "Flooring",
    options: [
      "Carpet in Office, VCT in Lab",
      "Polished Concrete",
      "Carpet",
      "None - Concrete",
      "Other",
    ],
  },
  {
    field: "ServerRoom",
    options: [
      "Yes with dedicated HVAC",
      "Closet no dedicated HVAC",
      "Data Center with Raised Floor",
      "None",
      "Potential",
      "Shared",
      "Other",
    ],
  },
  {
    field: "SpecializedAirHandling",
    options: [
      "Single Pass 7+ airchanges per hour",
      "Recirculated Air dedicated to Lab",
      "Recirculated Air",
      "None",
      "Potential",
      "Other",
    ],
  },
  {
    field: "BackupPower",
    options: [
      "Building Shared Generator",
      "Dedicated",
      "UPS",
      "Generator",
      "Potential",
      "Planned",
      "Other",
    ],
  },
  {
    field: "220Outlets",
    options: [
      "In Benches",
      "Walls Only",
      "Yes",
      "No",
      "Potential",
      "Planned",
      "Other",
    ],
  },
  {
    field: "ChemicalStorage",
    options: [
      "In Suite",
      "Basement",
      "Yes",
      "No",
      "Potential",
      "Planned",
      "Other",
    ],
  },
  {
    field: "CompressedAir",
    options: ["Centralized", "Yes", "No", "Potential", "Planned", "Other"],
  },
];

// const decimalFields = ["AskIncreases"];

const tabFields = {
  "Lease Availability Terms": [
    "DateOccupancy",
    "TotalSize",
    "SizeMin",
    "SizeMax",
    "SubleaseOrDirect",
    "AskRate",
    "AskTI",
    "Expenses",
    "Condition",
    "DateListed",
    // "DateAvailable",
    "TermAvailable",
    "Status",
    "AskIncreases",
    "EstFreeRent",
    "EstOutOfPocket",
    "PropertyType",
    "Encumbrances",
    "PreviousTenants",
    "Suite",
    "Floor",
    "RenewalOptions",
    "ExpansionOptions",
    "Notes",
    "RentStructure",
    "MaintenanceResponsibility",
    "JanitorialResponsibility",
    "LoadFactor",
    "UsableSpace",
  ],
  "Lease Transaction Terms": [
    "PropertyType",
    "Condition",
    "Suite",
    "Floor",
    "SubleaseOrDirect",
    "Notes",
    "RentStructure",
    "Tenant",
    "Landlord",
    "NewDealOrRenewal",
    "LeasedSpace",
    "ExecutionDate",
    "LeaseTermMonths",
    "CommencementDate",
    "DateOccupancy",
    "InitialRent",
    "ContractedEscalations",
    "Condition",
    "FreeRentMonths",
    "ImprovementAllowancePerFoot",
    "AmortizedImprovementAllowancePerFoot",
    "AmortizationRate",
    "ConstructionAbatementMonths",
    // "TenantBroker",
    "Expenses",
  ],
  "General Space": [
    "PropertyType",
    "DateOccupancy",
    "TotalSize",
    "SizeMin",
    "SizeMax",
    "SubleaseOrDirect",
    "Condition",
    // "DateAvailable",
    "TermAvailable",
    "Status",
    "PercentOffice",
    "PercentLab",
    "PercentWarehouse",
    "Suite",
    "Floor",
    "Notes",
    "DateConstructionComplete",
  ],

  "Life Science": [
    "PropertyType",
    "DateOccupancy",
    "SizeMin",
    "SizeMax",

    "LabEquipment",
    "DateConstructionComplete",
    "Sinks",
    "Hoods",
    "CompressedAir",

    "ConstructionStage",
    "ChemicalStorage",
    "AVEquipment",

    "LabCeilingHeight",
    "OfficeCeilingHeight",
    "Flooring",
    "DockDoors",
    "GradeDoors",
    "SpaceAmenities",
    "SpecializedAirHandling",
    "ServerRoom",
    "PercentOffice",
    "PercentLab",
    "PercentWarehouse",

    "WarehouseShellClearHeight",
    "ColumnSpacing",
    "BackupPower",
    "220Outlets",

    "HypotheticalCapacity",
    "CurrentCapacity",
  ],

  Infrastructure: [
    "PropertyType",
    "LightingSystem",
    "FiberLines",
    "OccupancyRating",
    "ConstructionStatus",
    "ServerRoom",
    "PercentOffice",
    "PercentLab",
    "PercentWarehouse",
    "PowerAmps",
    "PowerVolts",

    "WarehouseShellClearHeight",
    "ColumnSpacing",
    "BackupPower",
    "220Outlets",
  ],
  Industrial: [
    "PropertyType",
    "DateOccupancy",
    "DockDoors",
    "GradeDoors",
    "PowerAmps",
    "PowerVolts",
    "WarehouseShellClearHeight",
    "Racks",
    "FreightLegal",
  ],

  Office: [
    "PropertyType",
    "DateOccupancy",
    "Furniture",
    "Kitchen",
    "Views",
    "ExposedCeilings",
    "SpaceAmenities",
    "InternalStairwells",
    "PercentRaisedFloor",
    "HypotheticalCapacity",
    "CurrentCapacity",
  ],
  Compliance: [
    "PropertyType",
    "HazmatDesignation",
    "FreightLegal",
    "FireSafetySystem",
    "ComplianceUpgrades",
    "ComplianceYear",
  ],
};

function updateJson(jsonFieldIn, reqValueIn) {
  var whatWeSaveToJsonAndDB = reqValueIn;
  //jsonFieldIn = reqValueIn

  // we don't need to change teh jsonFieldIN, we just need return the proper value to set the new main data json

  if (reqValueIn && reqValueIn !== "") {
    if (reqValueIn === -1) {
      whatWeSaveToJsonAndDB = null;
    } else {
      whatWeSaveToJsonAndDB = reqValueIn;
    }
  } else {
    whatWeSaveToJsonAndDB = null;
  }
  // return jsonFieldIn;
  // //test - console.log("json" + jsonFieldIn);
  // //test - console.log("req" + reqValueIn);
  // //test - console.log("save" + whatWeSaveToJsonAndDB);
  return whatWeSaveToJsonAndDB;
}

function editClick(
  setEditModeFunctionIn,
  setEditButtonTextFunctionIn,
  editMode,
  AvailableDataIn,
  setData,
  contactId
) {
  if (editMode === "Edit") {
    setEditModeFunctionIn("View");
    setEditButtonTextFunctionIn(<Edit sx={{ width: 20, height: 20 }} />);

    var entries = [];
    for (const [key, value] of Object.entries(AvailableDataIn)) {
      entries.push({ fieldName: `${key}`, fieldValue: value });
    }
    var journalArray = [];
    entries.map((entry) => {
      if (document.getElementById(entry.fieldName)) {
        if (
          !(
            document.getElementById(entry.fieldName).value === "" &&
            !AvailableDataIn[entry.fieldName]
          )
        ) {
          if (
            AvailableDataIn[entry.fieldName]?.toString() !==
            document.getElementById(entry.fieldName).value
          ) {
            if (entry.fieldName.indexOf("Date") >= 0) {
              var date1 = new Date(AvailableDataIn[entry.fieldName]);
              var date2 = new Date(
                document.getElementById(entry.fieldName).value
              );
              var DifferenceTime = date2.getTime() - date1.getTime();

              var DifferenceDays = DifferenceTime / (1000 * 3600 * 24);

              if (-10 < DifferenceDays < 10) {
                //do nothing its close
              } else {
                journalArray.push({
                  availId: AvailableDataIn.id,
                  AuthorUserID: contactId,
                  Field: entry.fieldName,
                  OldValue: AvailableDataIn[entry.fieldName],
                  NewValue: document.getElementById(entry.fieldName).value,
                });
              }
            } else {
              journalArray.push({
                availId: AvailableDataIn.id,
                AuthorUserID: contactId,
                Field: entry.fieldName,
                OldValue: AvailableDataIn[entry.fieldName],
                NewValue: document.getElementById(entry.fieldName).value,
              });
            }
          }
        }
        AvailableDataIn[entry.fieldName] = updateJson(
          AvailableDataIn[entry.fieldName],
          document.getElementById(entry.fieldName).value
        );
      }
    });
    var newData = { ...AvailableDataIn };
    setData(newData);
    updateAvailable(AvailableDataIn, journalArray);
  } else {
    setEditModeFunctionIn("Edit");
    setEditButtonTextFunctionIn(<Save />);
  }
}

async function updateAvailable(data, journalArray) {
  //test - console.log("my Data" + data);
  // const qs = Object.keys(data) // turn the parameters into a get query string parameters style
  //   .map((key) =>
  //     key !== "images" && key !== "building" && key !== "contacts"
  //       ? `${key}=${encodeURIComponent(data[key])}`
  //       : ""
  //   )
  //   .join("&");

  var shortData = { ...data };

  var deleteFields = [
    "markedDelete",
    "createdAt",
    "updatedAt",
    "avail_groups",
    "generic_journals",
    "contacts",
    "building",
    "companies",
    "images",
  ];

  dateFields.map((field) => {
    if (shortData[field] === "") {
      shortData[field] = null;
    }
  });

  deleteFields.map((field) => {
    delete shortData[field];
  });

  var xhr = new XMLHttpRequest();

  xhr.onreadystatechange = function () {
    if (xhr.readyState === XMLHttpRequest.DONE) {
      //test - console.log(xhr.responseText);
    }
  };

  //test - console.log(process.env.REACT_APP_DATABASE + "api/updateAvailablePost");
  xhr.open(
    "POST",
    process.env.REACT_APP_DATABASE + "api/updateAvailablePost",
    true
  );
  xhr.setRequestHeader("Content-Type", "application/json");
  // alert(PropertyInfo.YearBuilt)

  xhr.send(
    JSON.stringify({
      data: shortData,
      id: data.id,
      journalArray: journalArray,
    })
  );

  // const qs1 = "&&data=" + encodeURIComponent(JSON.stringify(shortData));

  // //test - console.log(
  //   process.env.REACT_APP_DATABASE +
  //     "api/updateAvailable?id=" +
  //     shortData.id +
  //     qs1
  // );
  // var resultInfo = await fetch(
  //   process.env.REACT_APP_DATABASE + "api/updateAvailable?id=" + data.id + qs1
  // );

  // var var2 = await resultInfo.json();
  // //test - console.log("results = " + var2);
}

export default function AvailableDetail({
  Mode,
  PropertyInformation,
  // people,
  user,

  contactId,
  guest,
}) {
  var nfObject = new Intl.NumberFormat("en-US");

  var nfObjectRate = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  var nfObjectPercent = new Intl.NumberFormat("en-US", {
    style: "percent",
    maximumFractionDigits: 4,
  });
  const [files, setFiles] = useState([])
  const navigate = useNavigate()
  const [ApexInformation, setApexData] = useState(PropertyInformation);
  const [AvailableDataIn, setData] = useState(ApexInformation.avail);
  const [tabHighLight, setTabHighLight] = useState(
    PropertyInformation.avail.Status === "Lease Comp"
      ? "Lease Transaction Terms"
      : "Lease Availability Terms"
  );
  const [rerender, setRerender] = useState(false);
  const [showJournal, setShowJournal] = useState(false);

  // const [showWait, setShowWait] = useState(false)
  // const [listReset, setListReset] = useState(false)
  const [currentJournal, setCurrentJournal] = useState(false);
  const [groupsIn, setGroupsIn] = useState(ApexInformation.groups)

  const [peopleIn, setPeopleIn] = useState(
    PropertyInformation.avail.contacts.sort(
      (a, b) => a.avail_contacts[0].sortOrder - b.avail_contacts[0].sortOrder
    )
  );
  const [editMode, setEditMode] = useState("View");
  const [editButtonText, setEditButtonText] = useState(<Edit sx={{ width: 18, height: 18 }} />);
  var highestPermission = "None";
  var entries = [];
  for (const [key, value] of Object.entries(AvailableDataIn)) {
    entries.push({ fieldName: `${key}`, fieldValue: value });
  }
  var missingInfo = [];
  var icon = null;
  var askRate = "Please Inquire";
  var initialPics = [];
  //test - console.log("Available Detail line 94");
  //test - console.log(AvailableDataIn);
  //test - console.log(peopleIn);
  
  if (!groupsIn) {

    window.location.href = window.location.href;
  }
  if (guest === true || guest === 'true') {
    highestPermission = "View";
  } else {
   groupsIn.map((group) => {
      group.avail_groups.map((avail_group) => {
        if (
          highestPermission !== "View" &&
          highestPermission !== "Edit" &&
          highestPermission !== "Lead Edit"
        ) {
          highestPermission = avail_group.groupPermission;
        } else {
          if (
            (highestPermission === "View" &&
              avail_group.groupPermission === "Edit") ||
            avail_group.groupPermission === "Lead Edit"
          ) {
            highestPermission = avail_group.groupPermission;
          } else if (
            highestPermission === "Edit" &&
            avail_group.groupPermission === "Lead Edit"
          ) {
            highestPermission = avail_group.groupPermission;
          }
        }
      });
    });
  }


  // const handlePaste = (e) => {
  //   if (e.clipboardData.files.length && editMode === "Edit") {
  //     const fileObject = e.clipboardData.files[0];

  //     var data = new FormData()
  //     data.append('file', fileObject)


  //     fetch(process.env.REACT_APP_IMAGE, {
  //       method: 'POST',
  //       body: data
  //     }).then((imageResponse) => {
  //       imageResponse.json().then((image) => {
  //         image.source = image.message

  //         addAvailableImage(image)
  //         alert("Image Added Please Click Save to Check")
  //       })
  //     })
  //       .catch((err) => {
  //         alert("error")
  //         //test - console.log(err)
  //         alert(err.message)
  //       })



  //   } else {
  //     // alert(
  //     //   "No image data was found in your clipboard. Copy an image first or take a screenshot."
  //     // );
  //   }
  // }


  AvailableDataIn.images.map((image) => {

    initialPics.push({
      source: image.img ? image.img : null,
      "image url": image.img ? image.img : null,
      name: image.id,
    });
  });

  if (PropertyInformation.avail.Status === "Lease Comp") {
    icon = "comp";
  } else {
    if (!Mode) {
      icon = "space";
    } else {
      icon = Mode;
    }
  }
  //test - console.log("icon: " + icon);

  async function addAvailableImage(image) {
    const updatedImages = [...AvailableDataIn.images, {
      source: image.message,
      img: image.message,
      url: image.message,
      name: image.name,
      id: image.name
    }];

    var result = await axios.get(process.env.REACT_APP_DATABASE + "api/addAvailableImage", {
      params: {
        img: image.message,
        myId: user.contact.id,
        availId: AvailableDataIn.id,
        sortOrder: updatedImages.length
      }
    })
    setData({ ...AvailableDataIn, images: updatedImages });
  }

  return ( <>
      <nav className="sticky z-50 top-0">
        <Header
          key="1"
          placeholderLocation={
            ((AvailableDataIn.building.StreetNumber &&
              AvailableDataIn.building.StreetNumber.toLowerCase() != 'null') ?
              AvailableDataIn.building.StreetNumber : "") +
            " " +
            AvailableDataIn.building.StreetName +

            ((AvailableDataIn.building.Suite &&
              AvailableDataIn.building.Suite.toLowerCase() != 'null') ?
              " - Suite " + AvailableDataIn.building.Suite : "") + " " +
            ((AvailableDataIn.building.CampusName && AvailableDataIn.building.PropertyName &&
              (AvailableDataIn.building.CampusName.trim() !== AvailableDataIn.building.PropertyName.trim())) ?
              " | " + AvailableDataIn.building.CampusName : "") +
            (AvailableDataIn.building.PropertyName ? " | " + AvailableDataIn.building.PropertyName : "")



          }
          icon={icon}
          user={user}
          highestPermission={highestPermission}
          entityId={AvailableDataIn.id}
        />
        {/* <Helmet>
          <meta name="image" content={AvailableDataIn.images[0].img} />
          <meta property="og:image" content={AvailableDataIn.images[0].img} />
        </Helmet> */}
      </nav>
      <div
        style={{
          display: (mobileAndTabletCheck() ? '' : 'flex'),
          flexDirection: (mobileAndTabletCheck() ? '' : 'column'),
          overflowX: 'hidden',
          justifyContent: (mobileAndTabletCheck() ? '' : 'center'),
          alignItems: (mobileAndTabletCheck() ? '' : 'center')
        }}
      >
        <div
          style={{ maxWidth: 2000, paddingRight: mobileAndTabletCheck() ? 0 : 80, paddingLeft: mobileAndTabletCheck() ? 0 : 80 }}
        >
        <div style={{ padding: 10 }}>
          <div className="px-4  sm:px-6"></div>
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="sm:px-6">
              <div className="px-2 py-4">
                <h1 className="text-xl font-semibold text-gray-900">
                  {PropertyInformation.avail.Status === "Lease Comp"
                    ? "Lease Transaction"
                    : "Available Space"}
                </h1>
                <div className="mt-1 max-w-2xl text-sm text-gray-500">
                  {
                    (AvailableDataIn.building.StreetNumber &&
                      AvailableDataIn.building.StreetNumber.toLowerCase() != 'null') ?
                      AvailableDataIn.building.StreetNumber : ""}{" "}
                  {AvailableDataIn.building.StreetName},{" "}
                  {AvailableDataIn.building.City}{" "}

                </div>
                {AvailableDataIn.building.PropertyName || AvailableDataIn.building.CampusName ?
                  <><div className="mt-1 max-w-2xl text-sm text-gray-500">

                    {((!AvailableDataIn.building.PropertyName && AvailableDataIn.building.CampusName)
                      ||
                      (AvailableDataIn.building.CampusName && AvailableDataIn.building.PropertyName &&
                        (AvailableDataIn.building.CampusName.trim() !== AvailableDataIn.building.PropertyName.trim())) ?
                      AvailableDataIn.building.CampusName : "") +
                      (AvailableDataIn.building.PropertyName ? " | " + AvailableDataIn.building.PropertyName : "")}


                  </div>
                  </> : <></>}
                <div className="mt-1 max-w-2xl text-sm text-gray-500">
                  {AvailableDataIn.Floor !== 0 && AvailableDataIn.Floor
                    ? AvailableDataIn.Floor < 0
                      ? "Entire Building"
                      : "Floor " + AvailableDataIn.Floor
                    : ""}
                  {AvailableDataIn.Suite !== "0" && AvailableDataIn.Suite && AvailableDataIn.Floor && AvailableDataIn.Floor > 0 ? "," : ""}
                  {" "}
                  {AvailableDataIn.Suite !== "0" && AvailableDataIn.Suite
                    ? "Suite " + AvailableDataIn.Suite
                    : ""}{" "}
                </div>
                <div>
                  <Button variant="outlined" size='small' sx={{ color: 'black', borderColor: 'black' }} onClick={() => {
                    navigate(
                      "/property?id=" + AvailableDataIn.building.id)
                  }}>
                    Back to Building Page
                  </Button>
                </div>
              </div>

              <div></div>
              <div className="w-full">
                {/* <div className="flex flex-col"> */}
                <div className="w-full  md:max-h-300 md:w-500 self-center py-2 "
                  // onPaste={handlePaste}
                >
                  {editMode !== "Edit" ? (
                    // <div className="flex flex-col max-w-full sm:max-w-xl self-center">
                    // <div className="flex flex-col  max-w-full">
                    AvailableDataIn.images ? (
                      <KeenSlider key="1" dataIn={AvailableDataIn} />
                    ) : (
                      ""
                    )
                  ) : (
                    // </div>
                      // </div>
                      <ImageUploadGallery
                        initialImages={initialPics}
                        onDeleted={(image) =>
                          markDeleteImage(image, AvailableDataIn, setData)
                        }
                        onSortEnd={async (images) => {

                          await orderImages(
                            images,
                    
                            AvailableDataIn,
                            setData
                          )
                        }
                        }
                        action={process.env.REACT_APP_IMAGE}
                        onSuccess={(image) => {
                          addAvailableImage(image)
                        }}
                      />
                  
                  )}
                  {/* </div> */}
                </div>
              </div>
            </div>
            <JournalSplash
              open={showJournal}
              setOpen={setShowJournal}
              // journalsIn={AvailableDataIn.generic_journals}
              contactId={contactId}
              entityId={AvailableDataIn.id}
              type={"Available"}
            />


            <div style={{ paddingLeft: mobileAndTabletCheck() ? 10 : 30 }}>
              <div className="text-xl font-semibold text-gray-900" >
                {AvailableDataIn.Status !== "Lease Comp"
                  ? "Available Data"
                  : "Lease Transaction Data"}




                {highestPermission &&
                  (highestPermission === "Edit" ||
                    highestPermission === "Lead Edit" ||
                    contactId === 1) ? (
                  <Button variant={'outlined'} sx={{ marginLeft: 1, minWidth: 0, width: 20, height: 24, borderColor: 'black', color: 'black' }}
                    onClick={() =>
                      editClick(
                        setEditMode,
                        setEditButtonText,
                        editMode,
                        AvailableDataIn,
                        setData,
                        contactId
                      )
                    }

                  >
                    {editButtonText}
                  </Button>

                ) : (
                  ""
                )}

              </div>
            
              <div>
                <TabsCB
                  tabHighLight={tabHighLight}
                  setTabHighLight={setTabHighLight}
                  tabs={tabs}
                  dataIn={AvailableDataIn}
                  setData={setData}
                  update={updateAvailable}
                  editMode={editMode}
                  type="Avail"
                  contactId={contactId}
                />
              </div>
              <div className="  py-2 sm:px-6 ">
                <dl className="grid grid-cols-1 bg-gray-100 rounded-xl px-4 py-4 gap-x-4 gap-y-8 sm:grid-cols-2">
                  {entries.map((entry) => {
                    var opinions = [];
                  

                    if (
                      [
                        "id",
                        "markedDelete",
                        "Longitude",
                        "Latitude",
                        "createdAt",
                        "updatedAt",
                        "campus_id",
                        "avail_groups",
                        "contacts",
                        "buildings",
                        "websites",
                        "companies",
                        "parcels",
                        "campus",
                        "images",
                      ].indexOf(entry.fieldName) === -1
                    ) {
                      if (tabFields[tabHighLight].indexOf(entry.fieldName) !== -1) {
                        if (!entry.fieldValue) {
                          missingInfo.push(entry.fieldName);
                        }

                        if (editMode === "View") {
                          return (
                            <div
                              className={
                                (!entry.fieldValue &&
                                  entry.fieldName !== "Expenses" &&
                                  entry.fieldName !== "AskRate" &&
                                  entry.fieldName !== "AskTI") ||
                                  entry.fieldName === "StreetName" ||
                                  entry.fieldName === "StreetNumber"
                                  ? "hidden"
                                  : "col-span-2 sm:col-span-1 "
                              }
                            >
                              <div className="flex">
                                <div className="w-full flex items-start">
                                  <dt className="text-sm mt-1 font-medium text-gray-500 px-2 w-2-third sm:w-half">
                                    {entry.fieldName === "TermAvailable"
                                      ? entry.fieldName
                                        .replace(/([A-Z])/g, " $1")
                                        .trim() + " (Months)"
                                      : entry.fieldName === "AskRate"
                                        ? entry.fieldName
                                          .replace(/([A-Z])/g, " $1")
                                          .trim() + " ($/SF/Month)"
                                        : entry.fieldName === "AskTI"
                                          ? entry.fieldName
                                            .replace(/([A-Z])/g, " $1")
                                            .trim() + " (Allowance $/SF from LL)"
                                          : entry.fieldName
                                            .replace(/([A-Z])/g, " $1")
                                            .trim()}{" "}
                                    {/* <div className="w-10 h-10"> */}
                                    {/* </div> */}
                                  </dt>
                                  <dd
                                    className={
                                      textAreaFields.indexOf(entry.fieldName) > -1
                                        ? "mt-1 font-semibold text-sm text-gray-900 whitespace-pre-line w-full"
                                        : "mt-1 font-semibold text-sm text-gray-900 w-full"
                                    }
                                  >
                                    {(commaFields.indexOf(entry.fieldName) === -1) &
                                      (dateFields.indexOf(entry.fieldName) === -1)
                                      ? entry.fieldValue && entry.fieldValue !== ""
                                        ? entry.fieldName === "Floor" &&
                                          entry.fieldValue === -1
                                          ? "Entire Building"
                                          : currencyFields.indexOf(
                                            entry.fieldName
                                          ) === -1
                                            ? percentFields.indexOf(entry.fieldName) ===
                                              -1
                                              ? entry.fieldValue
                                              : nfObjectPercent.format(entry.fieldValue)
                                            : nfObjectRate.format(entry.fieldValue)
                                        : ""
                                      : commaFields.indexOf(entry.fieldName) >= 0
                                        ? entry.fieldValue && entry.fieldValue !== ""
                                          ? entry.fieldValue
                                            .toString()
                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                          : ""
                                        : entry.fieldValue
                                          ? new Date(
                                            entry.fieldValue
                                          ).toLocaleDateString()
                                          : ""}

                                    {/* <OpinionButton
                                      props={{
                                        contactId: contactId,
                                        entity: "availId",
                                        entityId: AvailableDataIn.id,
                                        groupsIn: ApexInformation.groups,
                                        fieldName: entry.fieldName,
                                        setData: setData,
                                        setRerender: setRerender,
                                        // setListReset: setListReset
                                        // setShowWait: setShowWait
                                      }}
                                    /> */}
                                  </dd>
                                  <dd className="mt-1 text-sm text-gray-900 px-2 w-half flex justify-center">
                                    {

                                      AvailableDataIn.generic_journals.map(
                                        (journal) => {
                                          ////test - console.log(journal);
                                          if (
                                            journal.Field === entry.fieldName &&
                                            (journal.OpinionValue || journal.Message)
                                          ) {
                                            opinions.push(journal);
                                          }
                                        }
                                      )}

                                    {opinions.length > 0 ? (
                                      <div className="text-sm text-gray-500 flex flex-col w-full items-center">
                                        <div className=" px-2 w-full">
                                          <OpinionList
                                            opinions={opinions}
                                            contactId={contactId}
                                            entityId={AvailableDataIn.id}
                                            setData={setData}
                                            entity={"availId"}
                                          // listReset={listReset}
                                          // setListReset={setListReset}
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </dd>
                                </div>
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div className="col-span-2 sm:col-span-1 ">
                              <dt className="text-sm font-medium text-gray-500">
                                {entry.fieldName === "TermAvailable"
                                  ? entry.fieldName
                                    .replace(/([A-Z])/g, " $1")
                                    .trim() + " (Months)"
                                  : entry.fieldName === "AskRate"
                                    ? entry.fieldName
                                      .replace(/([A-Z])/g, " $1")
                                      .trim() + " ($/SF/Month)"
                                    : entry.fieldName === "AskTI"
                                      ? entry.fieldName
                                        .replace(/([A-Z])/g, " $1")
                                        .trim() + " (Allowance $/SF from LL)"
                                      : entry.fieldName
                                        .replace(/([A-Z])/g, " $1")
                                        .trim()}{" "}
                              </dt>
                              <dd
                                className={
                                  textAreaFields.indexOf(entry.fieldName) > -1
                                    ? "mt-1 font-semibold text-sm text-gray-900 whitespace-pre-line"
                                    : "mt-1 font-semibold text-sm text-gray-900"
                                }
                              >
                                {textAreaFields.indexOf(entry.fieldName) > -1 ? (
                                  <InputComponent
                                    inputType={"textarea"}
                                    defaultShow={""}
                                    id={AvailableDataIn.id}
                                    name={entry.fieldName}
                                    valueIn={entry.fieldValue}
                                  />
                                ) : integerFields.indexOf(entry.fieldName) === -1 ? (
                                  <>
                                    {optionFields.filter(function (el) {
                                      if (el.field === entry.fieldName) {
                                        return true;
                                      }
                                    }).length > 0 ? (
                                      <InputComponent
                                        inputType={"simpleselect"}
                                        defaultShow={entry.fieldValue}
                                        id={AvailableDataIn.id}
                                        name={entry.fieldName}
                                        valueIn={entry.fieldValue}
                                        options={
                                          optionFields.filter(function (el) {
                                            if (el.field === entry.fieldName) {
                                              //test - console.log(el.options);
                                              return true;
                                            }
                                          })[0].options
                                        }
                                      />
                                    ) : dateFields.indexOf(entry.fieldName) === -1 ? (
                                      currencyFields.indexOf(entry.fieldName) ===
                                        -1 ? (
                                        percentFields.indexOf(entry.fieldName) ===
                                          -1 ? (
                                          commaFields.indexOf(entry.fieldName) ===
                                            -1 ? (
                                            <InputComponent
                                              inputType={"text"}
                                              defaultShow={entry.fieldValue}
                                              id={AvailableDataIn.id}
                                              name={entry.fieldName}
                                              valueIn={entry.fieldValue}
                                            />
                                          ) : (
                                            <InputComponent
                                              inputType={"number"}
                                              defaultShow={entry.fieldValue}
                                              id={AvailableDataIn.id}
                                              name={entry.fieldName}
                                              valueIn={entry.fieldValue}
                                            />
                                          )
                                        ) : (
                                          <InputComponent
                                            inputType={"decimal"}
                                            defaultShow={entry.fieldValue}
                                            id={AvailableDataIn.id}
                                            name={entry.fieldName}
                                            valueIn={entry.fieldValue}
                                          />
                                        )
                                      ) : (
                                        <InputComponent
                                          inputType={"currency"}
                                          defaultShow={entry.fieldValue}
                                          id={AvailableDataIn.id}
                                          name={entry.fieldName}
                                          valueIn={entry.fieldValue}
                                        />
                                      )
                                    ) : (
                                      <InputComponent
                                        inputType={"date"}
                                        defaultShow={
                                          entry.fieldValue
                                            ? new Date(entry.fieldValue)
                                            : null
                                        }
                                        id={AvailableDataIn.id}
                                        name={entry.fieldName}
                                        valueIn={entry.fieldValue}
                                      />
                                    )}
                                  </>
                                ) : (
                                  <InputComponent
                                    inputType={"number"}
                                    defaultShow={entry.fieldValue}
                                    id={AvailableDataIn.id}
                                    name={entry.fieldName}
                                    valueIn={entry.fieldValue}
                                  />
                                )}{" "}
                              </dd>
                            </div>
                          );
                        }
                      }
                    }
                  })}
                  </dl>
               

              <div className="border-gray-100 py-5 ">
                <ContactList
                  groupsIn={groupsIn}
                  peopleIn={peopleIn}
                  jsonformsDataIn={AvailableDataIn}
                  setPeopleIn={setPeopleIn}
                  // peopleCombo={people}
                  contactId={contactId}
                  highestPermission={highestPermission}
                  availOrComp={icon}
                  guest={user.contact.guest}
                />
              </div>
              <div style={{marginBottom: 40}} >
                <AvailableGroupList
                    groupsIn={groupsIn}
                    setGroupsIn={setGroupsIn}
                    available={AvailableDataIn}
                    availableId={AvailableDataIn.id}
                  contactId={user.contact.id}

                  highestPermission={highestPermission}
                  setData={setApexData}
                  guest={user.contact.guest}
                />
              </div>

              <div style={{marginTop: 5}}>
                <WebsiteList
                  mainPageData={AvailableDataIn}
                  setMainPageData={setData}
                  contactId={contactId}
                  highestPermission={highestPermission}
                  availId={AvailableDataIn.id}
                />
              </div>
            </div>
         
          
          </div>
          {/* </div> */}

        </div>
        </div>
      </div>
      </div>
    </>
  );
}

 export async function markDeleteImage(image, dataIn, setData) {
  ////test - console.log(image)

  var qs = "id=" + image.name || image.id;
  dataIn.images = arrayRemove(dataIn.images, image.name || image.id);
  //test - console.log("out");
  ////test - console.log(companyDataIn.images )
  var newjson = Object.assign({}, dataIn);
  setData(newjson);
  var resultInfo = await fetch(
    process.env.REACT_APP_DATABASE + "api/markDeleteImage?" +
    qs
  );
}

export function arrayRemove(arr, value) {
  //test - console.log("In");
  /// //test - console.log(arr)
  return arr.filter(function (ele) {
    return ele.id !== value;
  });
}

export async function orderImages(
  images,
  mainDataIn,
  setData
) {
  ////test - console.log(images)
  var newPicsOrder = [];
  var index = 0
  for (var image of images) {
    newPicsOrder.push({
      source: image.url,
      url: image.url,
      img: image.url,
      name: image.name,
      id: image.name,
    });
    var qs = "id=" + image.name + "&sortOrder=" + index;

    await fetch(
      process.env.REACT_APP_DATABASE + "api/sortAvailableImage?" +
      qs
    );
    index = index + 1
  }


  var newjson = { ...mainDataIn };
  newjson.images = newPicsOrder;
  setData(newjson);
}



function sortByProperty(property) {
  return function (a, b) {
    if (a[property] > b[property]) return 1;
    else if (a[property] < b[property]) return -1;

    return 0;
  };
}
const tabs = [
  {
    name: "Lease Availability Terms",
    href: "#",
    icon: ScaleOutlined,
    current: false,
  },
  {
    name: "Lease Transaction Terms",
    href: "#",
    icon: PencilIcon,
    current: false,
  },
  { name: "General Space", href: "#", icon: CubeIcon, current: false },
  {
    name: "Infrastructure",
    href: "#",
    icon: LightningBoltIcon,
    current: false,
  },

  { name: "Life Science", href: "#", icon: BeakerIcon, current: false },
  { name: "Industrial", href: "#", icon: LocalShipping, current: false },
  { name: "Office", href: "#", icon: Work, current: false },
  { name: "Compliance", href: "#", icon: CheckCircle, current: false },
];
