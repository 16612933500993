/**
 * Generates and adds a custom GeoJSON layer to a Mapbox GL map for displaying globe summaries.
 *
 * @param {Object} map - The Mapbox GL map instance.
 * @param {Object} clusterData - The GeoJSON data containing cluster information.
 */
export const generateGlobeSummaryLayer = (map, clusterData) => {
    // Ensure the map and your GeoJSON data are loaded
    if (!clusterData || !clusterData.features) {
      console.warn("Cluster data is not available or incorrectly formatted.");
      return;
    }
  
    const convertedData = {
      ...clusterData,
      features: clusterData.features.map((feature) => {
        const sizeInMillions = Math.round(
          feature.data["SUM(avails.SizeMax)"] / 1000000
        );
        const numberOfBuildings = feature.data.idArray.length;
  
        const textDisplay = `${sizeInMillions} Million SF\n${numberOfBuildings} Buildings\nAvailable`;
        const sizeFactor = sizeInMillions / 50;
        const textSize = sizeInMillions / 6;
  
        return {
          ...feature,
          properties: {
            ...feature.data, // Copy data to properties
            textDisplay, // Add the 'textDisplay' property
            sizeFactor,
            textSize,
          },
        };
      }),
    };
  
    if (map && clusterData) {
      const sourceId = "custom-geojson-source";
  
      if (map.getSource(sourceId)) {
        // Source exists, so just update its data
        map.getSource(sourceId).setData(convertedData);
      } else {
        // Source doesn't exist, create it and add a layer
        map.addSource(sourceId, {
          type: "geojson",
          data: convertedData,
        });
  
        // Add a layer using this source
        map.addLayer({
          id: "custom-geojson-layer",
          type: "symbol", // Example type, change as needed (e.g., 'circle', 'line')
          source: sourceId,
          layout: {
            "icon-image": "turning-circle", // Use your icon
            "text-field": "{textDisplay}", // Displays the textDisplay property
            "icon-size": ["get", "sizeFactor"], // Get sizeFactor value from properties
            "text-size": ["get", "textSize"], // Get textSize value from properties
          },
          paint: {
            "text-color": "#000000", // Text color
            "text-halo-color": "#ffffff", // Halo (border) color
            "text-halo-width": 2, // Halo width in pixels
            "text-halo-blur": 1, // Optional: Add blur to the halo for a softer effect
          },
        });
      }
    }
  };
  