import { GeoJsonLayer } from '@deck.gl/layers';
import { _TerrainExtension as TerrainExtension } from "@deck.gl/extensions";
import { mobileAndTabletCheck } from '../../Deck';

/**
 * Creates a GeoJsonLayer for building circles visualization.
 *
 * @param {Array} coordsCircles - The GeoJSON data for the circles.
 * @param {Object} popupInfo - Information about the currently highlighted object (e.g., for tooltips).
 * @returns {GeoJsonLayer} Configured GeoJsonLayer for building circles.
 */
export function generateBuildingCirclesLayer(coordsCircles, popupInfo) {
  return new GeoJsonLayer({
    id: "geojson-buildingCircles",
    data: mobileAndTabletCheck() ? [] : coordsCircles,
    extensions: [new TerrainExtension()],
    terrainDrawMode: "offset",
    stroked: true,
    pickable: true,
    pointType: "circle",
    getFillColor: [255, 20, 0],
    getLineColor: [255, 20, 0],
    getPointRadius: (d) => {
      if (
        d.properties.summary.comps === 0 &&
        d.properties.summary.avails === 0
      ) {
        return 0;
      }
      if (popupInfo && popupInfo.object.id === d.id) {
        return 12;
      } else {
        return 6;
      }
    },
    highlightColor: [255, 0, 0],
  });
}


