import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { Auth0Provider } from '@auth0/auth0-react';
import { CircularProgress } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import UserLookupRoute from "./UserLookupRoute";
import RequestEmailVerification from "./RequestEmailVerification";
import Validate from "./components/Validate/Validate";
import axios from "axios";
import { useEffect, useState } from "react";
import Privacy, { Terms } from "./Privacy";
import { createCookie } from "./components/Common/Cookies";
import { generateFingerprint } from "./FingerPrintJS";
import OneSignal from 'react-onesignal';
import EmailConfirmation from "./EmailConfirmation";


window.OneSignal = window.OneSignal || [];

const queryClient = new QueryClient();

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container); // Create a root once

const App = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [userEmail, setUserEmail] = useState(null);
  const [submittedEmail, setSubmittedEmail] = useState(null);
  const [guest, setGuest] = useState(false);
  const [isEmailPromptVisible, setEmailPromptVisible] = useState(false);
  const [needsEmailVerification, setNeedsEmailVerification] = useState(false);
  const [isOffline, setIsOffline] = useState(!navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOffline(false);
    const handleOffline = () => setIsOffline(true);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    if (!isOffline && submittedEmail) {
      handleEmailSubmit();
    }
  }, [submittedEmail, isOffline]);

  const handleEmailSubmit = async () => {
    if (submittedEmail) {
      try {
        const result = await axios.post(
          `${process.env.REACT_APP_DATABASE}api/updateUserEmail`,
          {
            newEmail: submittedEmail,
            userId: user.sub
          }
        );
        if (result) {
          setUserEmail(submittedEmail)
          setEmailPromptVisible(false);
        }
      } catch (error) {
        console.error('Error updating email:', error);
        alert('There was an error updating your email. Please try again.');
      }
    }
  };

  useEffect(() => {
    
    const handleAuthenticationFlow = async () => {
      if (isLoading || isOffline) return;
      if (isAuthenticated) {
        if (user.email) {
          setUserEmail(user.email);
        } else {
          const linkedAccounts = await checkForLinkedAccounts(user.sub);
          if (linkedAccounts?.email) {
            setUserEmail(linkedAccounts.email);
            setGuest(false);
            createCookie("guest", 'false', 2);

            if (!linkedAccounts.email_verified) {
              setNeedsEmailVerification(true)
            }
          } else {
            sessionStorage.setItem("smsUserId", user.sub);
            setEmailPromptVisible(true);
            setNeedsEmailVerification(true);

          }
        }
      } else {
        const fingerprint = await generateFingerprint();

        handleGuestUser(fingerprint);
      }
    };

    handleAuthenticationFlow();
  }, [isLoading, isAuthenticated, user, isOffline]);

  useEffect(() => {
    const setupOneSignal = async () => {
      if (!window.location.href.includes("localhost") && window.OneSignal) {
        await OneSignal.init({ appId: 'a234a1f5-303c-4faf-aa75-cc2f38392cc7' }).catch((error) => { console.log(error); });
      }
    };

    setupOneSignal();
  }, []);

  const checkForLinkedAccounts = async (userId) => {
    try {
      const results = await axios.post(`${process.env.REACT_APP_DATABASE}api/checkForLinkedEmailAccounts`, { userId });
      if (results?.data?.emailIdentities && results?.data?.smsIdentities && results?.data?.email) {

        
        return { email: results.data.email, email_verified: results.data.email_verified };
      } else if (results?.data?.emailIdentities && userId?.includes('email')) {
        const smsUserId = sessionStorage.getItem("smsUserId");
        const response = await linkAccounts(userId, smsUserId);
        if (response) {
          const emailRecords = response.filter(record => record.connection === 'email');
          if (emailRecords.length > 0) {
            const theEmail = emailRecords[0].profileData.email;

            const email_verified = emailRecords[0].profileData.email_verified
            if (theEmail) {
              return { email: theEmail, email_verified: email_verified };
            }
          }
        }
      } else {
        return { email: null, email_verified: false };
      }
    } catch (error) {
      console.error('Error checking linked accounts:', error);
    }
  };

  const handleGuestUser = async (fingerprint) => {
    setUserEmail(fingerprint);
    setGuest(true);
    createCookie("guest", 'true', 2);
  };

  const renderContent = () => {

    if (isLoading || isOffline) {
      return (
        <div className="absolute top-1/3 w-full items-center flex flex-col">
          <CircularProgress style={{ marginBottom: 20 }} />
          <div>{isOffline ? 'No internet connection' : '...Confirming Permission'}</div>
        </div>
      );
    } else {
      if (isEmailPromptVisible) {
        return <EmailConfirmation userEmail={userEmail} setSubmittedEmail={setSubmittedEmail} />;
      }

      if (needsEmailVerification) {
        return <RequestEmailVerification emailIn={userEmail} />;
      }

      return <UserLookupRoute email={userEmail} guest={guest} parentLoading={isLoading || isOffline} />;
    }
  };

  const urlParams = new URLSearchParams(window.location.search);
  const validate = urlParams.get("validate");
  const id = urlParams.get("id");
  const type = urlParams.get("type");
  const personId = urlParams.get("personId");
  const email = urlParams.get("error_description");
  const requestorId = urlParams.get("requestorId");
  const requestorEmail = urlParams.get("requestorEmail");
  const privacy = urlParams.get("privacy");
  const terms = urlParams.get("terms");
  const response = urlParams.get("response");

  if (privacy === "true") {
    return <Privacy />;
  } else if (terms === "true") {
    return <Terms />;
  }

  if (window.location.href.indexOf("supportSignUp=true") > 0) {
    window.location.href = window.location.origin;
  }

  if (validate) {
    return (
      <Validate
        id={id}
        type={type}
        personId={personId}
        requestorEmail={requestorEmail}
        requestorId={requestorId}
        response={response}
      />
    );
  }

  return renderContent();
};

root.render(
  <Auth0Provider
    domain="https://login.cubicsearch.com"
    clientId="vRT1q82hsmw9gQuXiL3jSglfIRRbjlNP"
    redirectUri={window.location.origin}
    useRefreshTokens={true} // Enable refresh tokens
    cacheLocation="localstorage" // Store tokens in localstorage
    connection="sms"
    authorizationParams={{
      redirect_uri: window.location.origin
    }}
  >
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </Auth0Provider>
);

async function linkAccounts(primaryUserId, secondaryUserId) {
  try {
    const response = await axios.post(`${process.env.REACT_APP_DATABASE}api/linkAccounts`, {
      primaryUserId,
      secondaryUserId
    });
    return response.data;
  } catch (error) {
    console.error('Error linking accounts:', error);
  }
}



export default App;

