// CompsGrid.jsx

import React from "react";
import { Grid, Typography } from "@mui/material";

const CompsGrid = ({
  comp, // The comp object to display
  mobileAndTabletCheck, // Function to check if the device is mobile or tablet
  nfObject, // Number formatting object for formatting numbers
  nfObjectRate, // Number formatting object for formatting rates
}) => {
  const handleClick = () => {
    window.open(
      `comp?id=${comp.id}`,
      mobileAndTabletCheck() ? "_self" : "_blank" // Use "_blank" for non-mobile devices
    );
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-start"
      spacing={2}
      onClick={handleClick}
      sx={{
        cursor: "pointer",
        padding: 2,
        "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.05)" },
      }}
      key={`GridRow-${comp.id}`}
    >
      {/* Tenant Information */}
      <Grid item xs={12} sm={6} md={3}>
        <Typography variant="caption" fontWeight="bold" sx={{ display: 'block' }}>
          Tenant:
        </Typography>
        <Typography variant="caption">{comp.Tenant}</Typography>
      </Grid>

      {/* Leased Space */}
      <Grid item xs={12} sm={6} md={3}>
        <Typography variant="caption" fontWeight="bold" sx={{ display: 'block' }}>
          Leased Space:
        </Typography>
        <Typography variant="caption">{`${nfObject.format(comp.LeasedSpace)} SF`}</Typography>
      </Grid>

      {/* Floor Information */}
      {comp.Floor && comp.Floor > 0 && (
        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="caption" fontWeight="bold" sx={{ display: 'block' }}>
            Floor:
          </Typography>
          <Typography variant="caption">{`Floor ${comp.Floor}`}</Typography>
        </Grid>
      )}

      {/* Suite Information */}
      {comp.Suite && comp.Suite.length > 0 && (
        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="caption" fontWeight="bold" sx={{ display: 'block' }}>
            Suite:
          </Typography>
          <Typography variant="caption">{`Suite ${comp.Suite}`}</Typography>
        </Grid>
      )}

      {/* Sublease or Direct Information */}
      {comp.SubleaseOrDirect && (
        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="caption" fontWeight="bold" sx={{ display: 'block' }}>
            Type:
          </Typography>
          <Typography variant="caption">{comp.SubleaseOrDirect}</Typography>
        </Grid>
      )}

      {/* Execution Date */}
      {comp.ExecutionDate && (
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="caption" fontWeight="bold" sx={{ display: 'block' }}>
            Signed:
          </Typography>
          <Typography variant="caption">
            {new Date(comp.ExecutionDate).toLocaleDateString()}
          </Typography>
        </Grid>
      )}

      {/* Condition */}
      {comp.Condition && (
        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="caption" fontWeight="bold" sx={{ display: 'block' }}>
            Condition:
          </Typography>
          <Typography variant="caption">{comp.Condition}</Typography>
        </Grid>
      )}

      {/* Property Type */}
      {comp.PropertyType && (
        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="caption" fontWeight="bold" sx={{ display: 'block' }}>
            Property Type:
          </Typography>
          <Typography variant="caption">{comp.PropertyType}</Typography>
        </Grid>
      )}

      {/* Initial Rent */}
      {comp.InitialRent && (
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="caption" fontWeight="bold" sx={{ display: 'block' }}>
            Rent:
          </Typography>
          <Typography variant="caption">
            {`${nfObjectRate.format(comp.InitialRent)} ${comp.RentStructure || ""}/SF/Mo`}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default CompsGrid;
