import * as React from "react";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Paper, Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

const nfObject = new Intl.NumberFormat("en-US");

// CustomTabPanel Component
function CustomTabPanel(props) {
  const { children, value, index, collapsed, ...other } = props;

  return (
    <Collapse in={value === index && !collapsed} timeout="auto" unmountOnExit>
      <Box
        role="tabpanel"
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        sx={{ p: 2 }}
      >
        <List>{children}</List>
      </Box>
    </Collapse>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.string.isRequired,
  value: PropTypes.string,
  collapsed: PropTypes.bool,
};

// Accessibility Props Helper
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// TabsComponent Implementation
export default function TabsComponent({
  availArray,
  compArray,
  totalAvail,
  printing,
}) {
  // Determine available tabs based on props
  const availableTabs = [];
  if (availArray && availArray.length > 0) {
    availableTabs.push("available-spaces");
  }
  if (compArray && compArray.length > 0) {
    availableTabs.push("lease-comps");
  }

  // Initialize 'value' to the first available tab to satisfy MUI
  const [value, setValue] = React.useState(
    availableTabs.length > 0 ? availableTabs[0] : false
  );

  // Initialize 'collapsedTabs' to include all tabs, so panels are collapsed initially
  const [collapsedTabs, setCollapsedTabs] = React.useState(
    new Set(availableTabs)
  );

  // Handle tab selection changes
  const handleChange = (event, newValue) => {
    event.stopPropagation();
    setValue(newValue);
    // When selecting a new tab, ensure its panel is expanded
    setCollapsedTabs((prev) => {
      const newSet = new Set(prev);
   
        newSet.delete("available-spaces"); // Collapse
        newSet.delete("lease-comps"); // Collapse
    
      return newSet;
    });
  };

  // Create Tab components based on available data
  const tabs = [];
  if (availArray && availArray.length > 0) {
    tabs.push(
      <Tab
        key="available-spaces"
        label={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" fontSize={10}>
              For Lease ({availArray.length}) |{" "}
              {totalAvail > 0 ? `${nfObject.format(totalAvail)} SF` : ""}
            </Typography>
            {/* IconButton to toggle collapse */}
            <IconButton
              size="small"
              sx={{ ml: 1 }}
              aria-label={
                collapsedTabs.has("available-spaces") ? "Expand" : "Collapse"
              }
              onClick={(e) => {
                e.stopPropagation(); // Prevent triggering tab change
                
                setCollapsedTabs((prev) => {
                  const newSet = new Set(prev);
                  if (newSet.has("available-spaces")) {
                    newSet.delete("available-spaces"); // Expand
                  } else {
                    newSet.add("available-spaces"); // Collapse
                  }
                  return newSet;
                });
              }}
            >
              {collapsedTabs.has("available-spaces") ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </IconButton>
          </Box>
        }
        value="available-spaces"
        onClick={(event)=>{handleChange(event,'available-spaces' )}}
        {...a11yProps("available-spaces")}
      />
    );
  }
  if (compArray && compArray.length > 0) {
    tabs.push(
      <Tab
        key="lease-comps"
        label={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body" fontSize={10}>
              Leased ({compArray.length})
            </Typography>
            {/* IconButton to toggle collapse */}
            <IconButton
              size="small"
              sx={{ ml: 1 }}
              aria-label={
                collapsedTabs.has("lease-comps") ? "Expand" : "Collapse"
              }
              onClick={(e) => {
                e.stopPropagation(); // Prevent triggering tab change
                setCollapsedTabs((prev) => {
                  const newSet = new Set(prev);
                  if (newSet.has("lease-comps")) {
                    newSet.delete("lease-comps"); // Expand
                  } else {
                    newSet.add("lease-comps"); // Collapse
                  }
                  return newSet;
                });
              }}
            >
              {collapsedTabs.has("lease-comps") ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </IconButton>
          </Box>
        }
        value="lease-comps"
        onClick={(event)=>{handleChange(event,'lease-comps' )}}
        {...a11yProps("lease-comps")}
      />
    );
  }

  return (
    <Box sx={{ width: "100%", paddingBottom: 2 }}>
      {/* Tabs Header */}
      <Box>
        <Tabs
          value={value}
        //   onChange={handleChange}
          aria-label="tabs with collapse"
          variant="fullWidth" // Optional: Adjust tab styling
        >
          {tabs}
        </Tabs>
      </Box>

      {/* Available Spaces Tab Panel */}
      {availArray && availArray.length > 0 && (
        <Box
          sx={{
            maxHeight: printing ? 400 : 600,
            overflow: "auto",
            "&::-webkit-scrollbar": { display: "none" },
          }}
        >
          <CustomTabPanel
            value={value}
            index="available-spaces"
            collapsed={collapsedTabs.has("available-spaces")}
          >
            {/* Replace with your actual content */}
            {availArray.map((item, idx) => (
              <Box key={idx} sx={{ mb: 1 }}>
                {/* Example content */}
                <Typography>{item}</Typography>
              </Box>
            ))}
          </CustomTabPanel>
        </Box>
      )}

      {/* Lease Comps Tab Panel */}
      {compArray && compArray.length > 0 && (
        <Paper
          sx={{
            maxHeight: printing ? 400 : 600,
            overflow: "auto",
            "&::-webkit-scrollbar": { display: "none" },
            mt: 2, // Optional: Adds some margin-top for separation
          }}
        >
          <CustomTabPanel
            value={value}
            index="lease-comps"
            collapsed={collapsedTabs.has("lease-comps")}
          >
            {/* Replace with your actual content */}
            {compArray.map((item, idx) => (
              <Box key={idx} sx={{ mb: 1 }}>
                {/* Example content */}
                <Typography>{item}</Typography>
              </Box>
            ))}
          </CustomTabPanel>
        </Paper>
      )}
    </Box>
  );
}
