// WebsitesSection.jsx

import React from "react";
import { Grid, Button, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import { truncateText } from "./truncateText"; // Adjust the import path as needed

const WebsitesSection = ({ websites, mobileAndTabletCheck, truncateLimit = 20 }) => {
  if (!websites || websites.length === 0) {
    return null; // Render nothing if there are no websites
  }

  return (
    <Grid container spacing={2} key={`websitesContainer`}>
      {websites.map((website, index) => (
        <Grid
          key={`${website.src}-${index}`}
          item
          xs="auto" // Adjust as needed for responsiveness
        >
          <Tooltip title={website.Description} arrow>
            <Button
              aria-label={`Visit ${website.Description}`}
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: 12, // Adjust font size as needed
                maxWidth: "150px", // Adjust maxWidth based on design requirements
                padding: "6px 12px", // Adjust padding for better fit
              }}
              variant="contained"
              onClick={() => {
                window.open(
                  website.src,
                  mobileAndTabletCheck() ? "_self" : "_blank"
                );
              }}
            >
              {truncateText(website.Description, truncateLimit)}
            </Button>
          </Tooltip>
        </Grid>
      ))}
    </Grid>
  );
};

// Define prop types for better type checking
WebsitesSection.propTypes = {
  websites: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      Description: PropTypes.string.isRequired,
    })
  ),
  mobileAndTabletCheck: PropTypes.func.isRequired,
  truncateLimit: PropTypes.number, // Optional: Allow customization of truncation limit
};

export default WebsitesSection;
