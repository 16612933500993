export function generateBillboardDimensions(
  data = {},
  options = {},
  d,
  selectedLocation
) {
  const {
    padding = 10,
    fontSize = 14,
    backgroundColor = "green",
    textColor = "black",
    borderRadius = 5,
    maxLineWidth = 180, // Maximum line width in pixels for word wrapping
  } = options;

  // Utility to handle missing or invalid fields
  const safeText = (text) => (text && typeof text === "string" ? text : "");

  const wrappedHeader = safeText(data.PropertyType)
    ? wrapText(data.PropertyType, maxLineWidth, fontSize)
    : [];
  const wrappedDetails = [];

  // Add other fields dynamically
  if (safeText(data.CampusName))
    wrappedDetails.push(...wrapText(data.CampusName, maxLineWidth, fontSize));
  if (safeText(data.Price))
    wrappedDetails.push(...wrapText(data.Price, maxLineWidth, fontSize));
  if (!data.availSF && !data.compSF && safeText(data.Size))
    wrappedDetails.push(...wrapText(data.Size, maxLineWidth, fontSize));
  if (safeText(data.availSF))
    wrappedDetails.push(...wrapText(data.availSF, maxLineWidth, fontSize));
  if (safeText(data.compSF))
    wrappedDetails.push(...wrapText(data.compSF, maxLineWidth, fontSize));

  // Construct SVG
  const totalLines = wrappedHeader.length + wrappedDetails.length;
  const width =  maxLineWidth + padding * 2;
  const height =  totalLines * fontSize * 1.2 + padding * 2 + 30; // Add height for header

  return {
    width,
    height,
    padding,
    wrappedHeader,
    wrappedDetails,
    backgroundColor,
    textColor,
    borderRadius,
    fontSize,
  };
}

export function generateBillboardSVG(
  data = {},
  options = {},
  d,
  selectedLocation
) {
  const {
    width,
    height,
    padding,
    wrappedHeader,
    wrappedDetails,
    backgroundColor,
    textColor,
    borderRadius,
    fontSize,
  } = generateBillboardDimensions(data, options);

  // Escape special characters in the text
  const escapeXML = (text) => {
    return text
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&apos;");
  };

  // Wrap header and details
  const headerContent = wrappedHeader
    .map(
      (line, index) => `
      <text x="${width / 2}" y="${
        20 + fontSize * index
      }" fill="white" text-anchor="middle" font-size="${fontSize}" font-family="Arial">
        ${escapeXML(line)}
      </text>`
    )
    .join("");

  const detailsContent = wrappedDetails
    .map(
      (line, index) => `
      <text x="${width / 2}" y="${
        30 + padding + fontSize * (index + 1)
      }" fill="${textColor}" text-anchor="middle" font-size="${fontSize}" font-family="Arial">
        ${escapeXML(line)}
      </text>`
    )
    .join("");

  // Construct SVG
  let backGroundColor = "white";
  if (selectedLocation && selectedLocation?.id === d?.id) {
    console.log(JSON.stringify(selectedLocation));
    console.log(JSON.stringify(d));
    backGroundColor = "yellow";
  }
  const svgContent = `
    <svg width="${width}" height="${height}" xmlns="http://www.w3.org/2000/svg">
      <!-- Outer border with rounded corners -->
      <rect x="0" y="0" width="${width}" height="${height}" rx="${borderRadius}" ry="${borderRadius}" fill="${backGroundColor}" stroke="${backgroundColor}" stroke-width="2" />
      
      <!-- Header Section -->
      ${
        wrappedHeader.length > 0
          ? `
      <rect x="0" y="0" width="${width}" height="30" fill="${backgroundColor}" />
      ${headerContent}
      `
          : ""
      }

      <!-- Details Section -->
      ${detailsContent}
    </svg>
  `;

  return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgContent)}`;
}

function wrapText(text, maxLineWidth, fontSize) {
  const words = text.split(" ");
  const lines = [];
  let currentLine = "";

  // Create a temporary SVG to measure text width
  const tempSvg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  const tempText = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "text"
  );
  tempText.setAttribute("font-family", "Arial");
  tempText.setAttribute("font-size", fontSize);
  tempSvg.appendChild(tempText);
  document.body.appendChild(tempSvg);

  try {
    words.forEach((word) => {
      tempText.textContent = `${currentLine} ${word}`.trim();
      const lineWidth = tempText.getBBox().width;

      if (lineWidth <= maxLineWidth) {
        currentLine = `${currentLine} ${word}`.trim();
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    });

    // Push the last line
    if (currentLine) {
      lines.push(currentLine);
    }
  } catch (error) {
    console.error("Error wrapping text:", error);
  } finally {
    // Ensure SVG is always cleaned up
    document.body.removeChild(tempSvg);
  }

  return lines;
}
