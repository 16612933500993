// AvailabilityGrid.jsx

import React from "react";
import { Grid, Typography } from "@mui/material";

const AvailabilityGrid = ({
  available,
  mobileAndTabletCheck,
  nfObject,
  nfObjectRate, // Ensure this prop is passed if used for formatting rates
  myDate, // Ensure this prop is passed if used for Occupancy
}) => {
  const handleClick = () => {
    window.open(
      `available?id=${available.id}`,
      mobileAndTabletCheck() ? "_self" : "_blank" // Use "_blank" for non-mobile devices
    );
  };

  const getSizeLabel = () => {
    if (!available.SizeMin || available.SizeMin === 0) {
      return "Call for size";
    }
    if (available.SizeMin !== available.SizeMax) {
      return `${nfObject.format(available.SizeMin)} - ${nfObject.format(
        available.SizeMax
      )} SF`;
    }
    return `${nfObject.format(available.SizeMin)} SF`;
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-start"
      spacing={2}
      onClick={handleClick}
      sx={{
        cursor: "pointer",
        padding: 2,
        "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.05)" },
      }}
      key={`GridRow-${available.id}`}
    >
      {/* Size Information */}
      <Grid item xs={12} sm={6} md={3}>
        <Typography variant="caption" fontWeight="bold" sx={{ display: 'block' }}>
          Size:
        </Typography>
        <Typography variant="caption">{getSizeLabel()}</Typography>
      </Grid>

      {/* Floor Information */}
      {available.Floor > 0 && (
        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="caption" fontWeight="bold" sx={{ display: 'block' }}>
            Floor:
          </Typography>
          <Typography variant="caption">{available.Floor}</Typography>
        </Grid>
      )}

      {/* Suite Information */}
      {available.Suite && available.Suite.length > 0 && (
        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="caption" fontWeight="bold" sx={{ display: 'block' }}>
            Suite:
          </Typography>
          <Typography variant="caption">{available.Suite}</Typography>
        </Grid>
      )}

      {/* Sublease or Direct Information */}
      {available.SubleaseOrDirect && (
        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="caption" fontWeight="bold" sx={{ display: 'block' }}>
            Type:
          </Typography>
          <Typography variant="caption">{available.SubleaseOrDirect}</Typography>
        </Grid>
      )}

      {/* Occupancy Date */}
      {myDate && (
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="caption" fontWeight="bold" sx={{ display: 'block' }}>
            Occupancy:
          </Typography>
          <Typography variant="caption">{myDate}</Typography>
        </Grid>
      )}

      {/* Condition */}
      {available.Condition && (
        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="caption" fontWeight="bold" sx={{ display: 'block' }}>
            Condition:
          </Typography>
          <Typography variant="caption">{available.Condition}</Typography>
        </Grid>
      )}

      {/* Ask Rate */}
      {available.AskRate && (
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="caption" fontWeight="bold" sx={{ display: 'block' }}>
            Rent:
          </Typography>
          <Typography variant="caption">
            {nfObjectRate.format(available.AskRate)}{" "}
            {available.RentStructure || ""}/SF/Mo
          </Typography>
        </Grid>
      )}

      {/* Expenses */}
      {available.Expenses && (
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="caption" fontWeight="bold" sx={{ display: 'block' }}>
            Expenses:
          </Typography>
          <Typography variant="caption">
            {nfObjectRate.format(available.Expenses)}/SF/Mo
          </Typography>
        </Grid>
      )}

      {/* Dock Doors */}
      {available.DockDoors && (
        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="caption" fontWeight="bold" sx={{ display: 'block' }}>
            Dock Doors:
          </Typography>
          <Typography variant="caption">{available.DockDoors}</Typography>
        </Grid>
      )}

      {/* Grade Doors */}
      {available.GradeDoors && (
        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="caption" fontWeight="bold" sx={{ display: 'block' }}>
            Grade Doors:
          </Typography>
          <Typography variant="caption">{available.GradeDoors}</Typography>
        </Grid>
      )}

      {/* Warehouse Shell Clear Height */}
      {available.WarehouseShellClearHeight && (
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="caption" fontWeight="bold" sx={{ display: 'block' }}>
            Clear Height:
          </Typography>
          <Typography variant="caption">
            {available.WarehouseShellClearHeight} ft
          </Typography>
        </Grid>
      )}

      {/* Power Volts */}
      {available.PowerVolts && (
        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="caption" fontWeight="bold" sx={{ display: 'block' }}>
            Volts:
          </Typography>
          <Typography variant="caption">{available.PowerVolts} V</Typography>
        </Grid>
      )}

      {/* Power Amps */}
      {available.PowerAmps && (
        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="caption" fontWeight="bold" sx={{ display: 'block' }}>
            Amps:
          </Typography>
          <Typography variant="caption">{available.PowerAmps} A</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default AvailabilityGrid;
