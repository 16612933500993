// src/ThumbnailList.jsx

import React, { useRef, useState, useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import PropTypes from 'prop-types'; // For prop type validation

/**
 * LazyImage Component
 * Responsible for lazy-loading images using Intersection Observer.
 */
const LazyImage = ({ src, alt }) => {
  const imgRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const currentImg = imgRef.current;
    let observer;

    if (currentImg) {
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              setIsVisible(true);
              observer.unobserve(currentImg);
            }
          });
        },
        { threshold: 0.1 }
      );
      observer.observe(currentImg);
    }

    return () => {
      if (observer && currentImg instanceof Element) {
        observer.unobserve(currentImg);
      }
    };
  }, []);

  return (
    <Box
      ref={imgRef}
      sx={{
        width: 100,
        height: 100,
        backgroundColor: '#f0f0f0',
        borderRadius: 1,
        overflow: 'hidden',
        position: 'relative',
        cursor: 'pointer', // Indicate clickable thumbnails
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {isVisible ? (
        <img
          src={src}
          alt={alt || 'Thumbnail'}
          style={{ 
            maxWidth: '100%', 
            maxHeight: '100%', 
            objectFit: 'contain' 
          }}
        />
      ) : (
        // Optional: Placeholder or Skeleton can be added here
        <Box sx={{ width: '100%', height: '100%', backgroundColor: '#e0e0e0' }} />
      )}
    </Box>
  );
};

LazyImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

/**
 * ThumbnailList Component
 * Displays a horizontally scrollable list of thumbnails with lazy-loading and navigation arrows.
 */
const ThumbnailList = ({ images, availId, mobileAndTabletCheck }) => {
  const containerRef = useRef(null);
  const [showArrows, setShowArrows] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setShowArrows(containerRef.current.scrollWidth > containerRef.current.clientWidth);
      }
    };

    // Initial check
    handleResize();

    // Debounce the resize handler for performance
    let resizeTimeout;
    const debouncedHandleResize = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(handleResize, 150);
    };

    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
      clearTimeout(resizeTimeout);
    };
  }, [images]);

  const scroll = (scrollOffset) => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: scrollOffset, behavior: 'smooth' });
    }
  };

  const handleClick = () => {
    window.open(
      `available?id=${availId}`,
      mobileAndTabletCheck() ? "_self" : "_blank" // Use "_blank" for non-mobile devices
    );
  };

  return (
    <Box sx={{ position: 'relative', width: '100%', my: 2 }}>
      {showArrows && (
        <>
          <IconButton
            onClick={() => scroll(-150)}
            sx={{
              position: 'absolute',
              top: '50%',
              left: 0,
              transform: 'translateY(-50%)',
              backgroundColor: 'rgba(255,255,255,0.7)',
              '&:hover': { backgroundColor: 'rgba(255,255,255,0.9)' },
              zIndex: 1,
            }}
            aria-label="Scroll thumbnails left"
          >
            <ArrowBackIos />
          </IconButton>
          <IconButton
            onClick={() => scroll(150)}
            sx={{
              position: 'absolute',
              top: '50%',
              right: 0,
              transform: 'translateY(-50%)',
              backgroundColor: 'rgba(255,255,255,0.7)',
              '&:hover': { backgroundColor: 'rgba(255,255,255,0.9)' },
              zIndex: 1,
            }}
            aria-label="Scroll thumbnails right"
          >
            <ArrowForwardIos />
          </IconButton>
        </>
      )}

      <Box
        ref={containerRef}
        sx={{
          display: 'flex',
          overflowX: 'auto',
          scrollBehavior: 'smooth',
          '&::-webkit-scrollbar': { display: 'none' }, // Hide scrollbar
        }}
        onClick={handleClick}
      >
        {images.map((image, index) => (
          <Box key={index} sx={{ flex: '0 0 auto', mr: 1 }}>
            <LazyImage src={image.img} alt={image.alt || `Thumbnail ${index + 1}`} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

ThumbnailList.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      img: PropTypes.string.isRequired,
      alt: PropTypes.string, // Optional: Provide meaningful alt text
    })
  ).isRequired,
  availId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  mobileAndTabletCheck: PropTypes.func.isRequired,
};

export default ThumbnailList;
