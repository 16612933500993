// returnBillboardSummariesLayer.js

import { GeoJsonLayer } from "deck.gl";
import { _TerrainExtension as TerrainExtension } from "@deck.gl/extensions";
import { ScenegraphLayer } from "deck.gl";
/**
 * Creates the getTextFunctionState function.
 *
 * @param {Function} sizeThresholds - Function to determine size based on parameters.
 * @param {number} camElevation - Current camera elevation.
 * @param {number} distanceFromGround - Distance from the ground.
 * @returns {Function} - A function that generates text based on data.
 */
const createGetTextFunctionState = (
  sizeThresholds,
  camElevation,
  distanceFromGround
) => {
  return (d) => {
    const size = sizeThresholds(d, camElevation, distanceFromGround);

    const types = [
      "LifeScience",
      "Office",
      "Industrial",
      "Flex",
      "Retail",
      "Other",
      "Land",
      "Medical",
      "Overlap",
    ];
    const arrayOfSpace = [];

    for (const type of types) {
      if (d.data.ConsolidatedSF[type]?.count > 0) {
        arrayOfSpace.push({
          type: type,
          pair: d.data.ConsolidatedSF[type],
        });
      }
    }

    let extraInfo = "";

    if (arrayOfSpace.length >= 3) {
      extraInfo = `${arrayOfSpace.length} Uses \n`;
    } else if (arrayOfSpace.length === 1) {
      extraInfo = `${arrayOfSpace[0].type} \n`;
    } else if (arrayOfSpace.length === 2) {
      extraInfo = `${arrayOfSpace[0].type}, ${arrayOfSpace[1].type} \n`;
    }

    if (d.data["SUM(avails.LeasedSpace)"]) {
      const leasedSpace = d.data["SUM(avails.LeasedSpace)"];
      if (leasedSpace / 100000 < 10) {
        extraInfo += `${Math.round(leasedSpace / 1000)}K SF \n`;
      } else {
        extraInfo += `${Math.round(leasedSpace / 1000000)} Million SF \n`;
      }
    } else if (d.data["SUM(avails.SizeMax)"]) {
      const sizeMax = d.data["SUM(avails.SizeMax)"];
      if (sizeMax / 100000 < 10) {
        extraInfo += `${Math.round(sizeMax / 1000)}K SF \n`;
      } else {
        extraInfo += `${Math.round(sizeMax / 1000000)} Million SF \n`;
      }
    }

    return `${extraInfo} ${d.data.idArray.length} Bldg(s)`;
  };
};

/**
 * Creates the GeoJsonLayer for state summaries.
 *
 * @param {Object} options - Configuration options.
 * @param {Object} options.summaryAvailData - GeoJSON data for clustering.
 * @param {Function} options.mobileAndTabletCheck - Function to check if device is mobile or tablet.
 * @param {Object} options.ICON_MAPPING - Icon mapping configuration for deck.gl.
 * @param {Object} options.mapRef - Reference to the map instance (e.g., Mapbox).
 * @param {Function} options.sizeThresholds - Function to determine size based on parameters.
 * @param {number} options.camElevation - Current camera elevation.
 * @param {number} options.distanceFromGround - Distance from the ground.
 * @returns {GeoJsonLayer} - Configured GeoJsonLayer instance.
 */
export const createAvailSummaryLayer = ({
  summaryAvailData,
  mobileAndTabletCheck,

  mapRef,
  sizeThresholds,
  camElevation,
  distanceFromGround,
}) => {
  const stateSummaryLayer = new ScenegraphLayer({
    id: "scenegraph-summaries-availables",
    data:
      summaryAvailData && summaryAvailData.features
        ? summaryAvailData.features
        : [],

    sizeMinPixels: 5,
    sizeMaxPixels: 15,
    scenegraph: "/models/redmarker.glb",
    // extensions: mobileAndTabletCheck() ? [] : [new TerrainExtension()],
    // terrainDrawMode: mobileAndTabletCheck() ? null : "offset",
    extensions: [new TerrainExtension()],
    terrainDrawMode: "offset",
    getPosition: (d) => {
      return [
        d.geometry.coordinates[0],
        d.geometry.coordinates[1],
        d.geometry.coordinates[2] - 20,
      ];
    },
    getOrientation: (d) => [0, 0, 90],

    sizeScale: 1,
    _animations: {
      "*": { speed: 1 },
    },
    _lighting: "pbr",

    pickable: true,

    onClick: (info) => {
      alert(
        "Available " +
          info.object.properties.PropertyType +
          " " +
          info.object.properties["SUM(avails.SizeMax)"] +
          " SF"
      );
    },
  });

  // Define the PulsingGeoJsonLayer for pulsing circles

  return stateSummaryLayer;
};

export const createCompSummaryLayer = ({
  summaryCompData,
  mobileAndTabletCheck,

  mapRef,
  sizeThresholds,
  camElevation,
  distanceFromGround,
}) => {
  // Create the getTextFunctionState using the provided dependencies
  const getTextFunctionState = createGetTextFunctionState(
    sizeThresholds,
    camElevation,
    distanceFromGround
  );

  const stateSummaryLayer = new ScenegraphLayer({
    id: "scenegraph-summaries-comp",
    data:
      summaryCompData && summaryCompData.features
        ? summaryCompData.features
        : [],

    sizeMinPixels: 3,
    sizeMaxPixels: 10,
    scenegraph: "/models/greenmarkerhigh.glb",
    extensions: mobileAndTabletCheck() ? [] : [new TerrainExtension()],
    terrainDrawMode: mobileAndTabletCheck() ? null : "offset",
    getPosition: (d) => {
      return [
        d.geometry.coordinates[0],
        d.geometry.coordinates[1],
        d.geometry.coordinates[2] - 20,
      ];
    },
    getOrientation: (d) => [0, 0, 90],

    sizeScale: 1,
    _animations: {
      "*": { speed: 1 },
    },
    _lighting: "pbr",

    pickable: true,

    onClick: (info) => {
      alert(
        "Comp " +
          info.object.properties.PropertyType +
          " " +
          info.object.properties["SUM(avails.LeasedSpace)"] +
          " SF"
      );
    },
  });

  // Define the PulsingGeoJsonLayer for pulsing circles

  return stateSummaryLayer;
};
