import InfoCard from "./InfoCard";
import { FlyToInterpolator } from "deck.gl";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from "react";
import ClusterCard, { ClusterCardTotals } from "./ClusterCard";
import { useState } from "react";
import FilterCard from "../Common/FilterDialogue/FilterCard";
import ClusterCardComp, { ClusterCardTotalsComp } from "./ClusterCardComps"


import { mobileAndTabletCheck } from "./Deck";
import { createCookie, getCookie } from "../Common/Cookies";


export default function InfoCards({
  filterInputs,
    searchResults,
  setSearchResults,
  showMe,
  removeProperty,
  onHover,
  onClick,
  showSurveys,
  setShowSurveys,
  surveyId,
  selectedLocation,
  setSelectedLocation,
  remove,
  confirm,
  contactId,
  printing,
  setInitialViewState,
  setSearchedLocations,
  setMapButton,
  clusterData,
  clusterTotal,
  clusterTotalComps,
  viewState,
  setSaveSurveyOpen,
  refs }) {


  var targetId

  try {
    // var rawCookie = getCookie("selectedLocation")
    targetId = selectedLocation?.id
  }
  catch (error) {
    console.log(error)
  }
  //test - console.log("Showing Cards")
  function ReturnInfos({ searchResults }) {


    if (searchResults && searchResults.length > 50 || !searchResults) {
      return (<></>)
    }
    if (refs && refs.current) {
      refs.current.length = 0


      return (searchResults.map((result, index) => {



        return (

          <div
            key={index}
            ref={ref => ref ? refs.current.push(ref) : <></>}
            style={{ paddingBottom: 3,

             }}
            onClick={() => {
              if (result.id !== targetId) {
     
                setSelectedLocation(result)
              }
              else {
        
                setSelectedLocation(null)
              }
            }}
            className={
              printing
                ? "pagebreak"
                : targetId === result.id
                  ? "pagebreak " + "selected-location"
                  : "pagebreak " + ""
            }
          >
            <InfoCard
              key={result.id}
              property={result}
              onHover={onHover}
              showMe={showMe}
              onClick={onClick}
              searchResults={searchResults}
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
              setSearchResults={setSearchResults}
              removeButton={remove}
              confirm={confirm}
              contactId={contactId}
              printing={printing}
              index={index}
              setSearchedLocations={setSearchedLocations}
              setMapButton={setMapButton}
              removeProperty={removeProperty}
              showSurveys={showSurveys}
              setShowSurveys={setShowSurveys}
              surveyId={surveyId}
              setSaveSurveyOpen={setSaveSurveyOpen}

            />
          </div>
        )

      }))


    }

  }



  if ( // if there are less than 3 clusters we show them all on the side, otherwise we just show one
    ((clusterData && clusterData.length < 6) && ((!searchResults) || (searchResults.length === 0)))) {


    return (
      <>
        {clusterData.map((cluster, index) => {



          if (filterInputs.availablesCheck && (filterInputs.compsCheck) || filterInputs.allCheck) {
            
            return (


              <>

                {cluster.data["SUM(avails.SizeMax)"] ? <>
                  <div key={"cluster" + index}>
                  
                    <ClusterCard cluster={{ data: cluster.data, contactId: contactId, viewState: viewState }} />
                  </div> </>
                  : <></>}

                {cluster.data["SUM(avails.LeasedSpace)"] ? <>
                  <div key={"cluster" + index}>
               
                    <ClusterCardComp cluster={{ data: cluster.data, contactId: contactId, viewState: viewState }} />
                  </div></>
                  : <></>}

                </>
            )
          }
                else if (filterInputs.availablesCheck && (!filterInputs.compsCheck)) {

            return (
                <>
                  <div key={"cluster" + index}>
                    <ClusterCard cluster={{ data: cluster.data, contactId: contactId, viewState: viewState }} />
                  </div>


                </>
                )
          }
                else if (!filterInputs.availablesCheck && (filterInputs.compsCheck)) {

            return (
                <>
                  <div key={"cluster" + index}>
                    <ClusterCardComp cluster={{ data: cluster.data, contactId: contactId, viewState: viewState }} />
                  </div>


                </>
                )
          }

        })}
                <div style={{ padding: 20 }}>
                  {mobileAndTabletCheck() ? <></> : <FilterCard filterInputs={filterInputs} />}
                </div>
              </>
            )




          }
          else if ((clusterData && clusterData.length >= 6) || (!searchResults) || (searchResults.length === 0)) {

    if (filterInputs.availablesCheck && (filterInputs.compsCheck) || filterInputs.allCheck) {
              
 
      var totalBuildingsAvail = 0
      var totalBuildingsComps = 0
      clusterData?.map((eachCluster) => {
   
        if (eachCluster.data["SUM(avails.SizeMax)"])
        {  totalBuildingsAvail = totalBuildingsAvail + eachCluster.data.idArray.length
        
        }
        else if (eachCluster.data["SUM(avails.LeasedSpace)"]) {
          totalBuildingsComps = totalBuildingsComps + eachCluster.data.idArray.length
        }
      })

      
              return (
                <>



                  <div>

            
                    {totalBuildingsAvail >0? <ClusterCardTotals cluster={{
                      data: clusterTotal, contactId: contactId,
                      viewState: viewState, totalBuildings: totalBuildingsAvail
                    }} />: <></>}
                  </div>
                  <div>
                 
                    {totalBuildingsComps > 0 ? <ClusterCardTotalsComp cluster={{
                      data: clusterTotalComps, contactId: contactId,
                      viewState: viewState, totalBuildings: totalBuildingsComps
                    }} />: <></>}
                  </div>
                  <div style={{ padding: 20 }}>
                    {mobileAndTabletCheck() ? <></> : <FilterCard filterInputs={filterInputs} />}
                  </div>

                  <ReturnInfos searchResults={searchResults} />

                </>
              )
            }
            else if (filterInputs.availablesCheck && (!filterInputs.compsCheck) && !filterInputs.allCheck) {
              return (
                <>



                  <div>



                    {totalBuildingsAvail>0?<ClusterCardTotals cluster={{ data: clusterTotal, contactId: contactId, viewState: viewState, totalBuildings: totalBuildingsAvail }} />:<></>}

                  </div>

                  <div style={{ padding: 20 }}>
                    {mobileAndTabletCheck() ? <></> : <FilterCard filterInputs={filterInputs} />}
                  </div>

                  <ReturnInfos searchResults={searchResults} />

                </>
              )
            }
            else if (!filterInputs.availablesCheck && (filterInputs.compsCheck) && !filterInputs.allCheck) {
              
              return (
                <>



                  <div>

                    {totalBuildingsComps? <ClusterCardTotalsComp cluster={{
                      data: clusterTotalComps,
                      contactId: contactId, viewState: viewState,
                      totalBuildings: totalBuildingsComps
                    }} />:<></>}
                  </div>

                  <div style={{ padding: 20 }}>
                    {mobileAndTabletCheck() ? <></> : <FilterCard filterInputs={filterInputs} />}
                  </div>

                  <ReturnInfos searchResults={searchResults} />

                </>
              )
            }




          }




          else
            if ((!searchResults || (searchResults.length === 0)) && (!clusterData || clusterData.length === 0)) {


              return (


                <>    <Card sx={{ maxWidth: 600 }}>
                  <CardMedia
                    sx={{ height: 300 }}
                    image="https://images.cubicsearch.com/noresults.png"
                    title="Keep Searching!"
                  />
                  <CardContent>
                    <Typography component={'div'}gutterBottom variant="h5" >
                      Keep Searching!
                    </Typography>
                    <Typography component={'div'}variant="body2" color="text.secondary">
                      No Results.  Please move map or search for results...
                    </Typography>
                  </CardContent>
                  <CardActions>
                    {/* <Button size="small">Share</Button>
            <Button size="small">Learn More</Button> */}
                  </CardActions>
                </Card>
                  <div style={{ padding: 20 }}>
                    {mobileAndTabletCheck() ? <></> : <FilterCard filterInputs={filterInputs} />}
                  </div>
                </>
              )

            }



            else {


              if (!searchResults) {
                return <></>
              }
              if (refs && refs.current) {
                refs.current.length = 0


                return (searchResults.map((result, index) => {



                  return (

                    <div
                      key={index}
                      ref={ref => ref ? refs.current.push(ref) : <></>}
                      style={{ paddingBottom: 3 }}
                      onClick={() => {
                        if (result.id !== targetId) {
             
                          setSelectedLocation(result)
                        }
                        else {
                   
                          setSelectedLocation(null)
                        }
                      }}
                      className={
                        printing
                          ? "pagebreak"
                          : targetId === result.id
                            ? "pagebreak " + "selected-location"
                            : "pagebreak " + ""
                      }
                    >
                      <InfoCard
                        key={result.id}
                        selectedLocation={selectedLocation}
                        setSelectedLocation={setSelectedLocation}
                        property={result}
                        onHover={onHover}
                        showMe={showMe}
                        onClick={onClick}
                        searchResults={searchResults}
                        setSearchResults={setSearchResults}
                        removeButton={remove}
                        confirm={confirm}
                        contactId={contactId}
                        printing={printing}
                        index={index}
                        setSearchedLocations={setSearchedLocations}
                        setMapButton={setMapButton}
                        removeProperty={removeProperty}
                        showSurveys={showSurveys}
                        setShowSurveys={setShowSurveys}
                        surveyId={surveyId}
                        setSaveSurveyOpen={setSaveSurveyOpen}

                      />
                    </div>
                  )

                }))

              }


            }



        }

