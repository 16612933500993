import { IconLayer, ColumnLayer, GeoJsonLayer } from '@deck.gl/layers';
import React, { useState, useEffect, useCallback, useRef, useMemo, forwardRef } from 'react';
import DeckGL from '@deck.gl/react';

import { FlyToInterpolator, MapView } from 'deck.gl';
import Map from 'react-map-gl';  // Importing default as Map, which acts as StaticMap
import { Tile3DLayer } from '@deck.gl/geo-layers';
import _ from 'lodash';
import { Slider, Box, Typography, } from '@mui/material';

import Supercluster from 'supercluster';
import { WebMercatorViewport } from 'deck.gl';
import axios from 'axios';
import { debounce } from 'lodash';
import { fitBounds } from 'viewport-mercator-project'; // This utility helps to calculate the new viewport
import { useImperativeHandle } from 'react';
import * as turf from '@turf/turf'




import {
    // DataFilterExtension,
    _TerrainExtension as TerrainExtension
} from '@deck.gl/extensions';
import { isDeepEqual } from '@mui/x-data-grid/internals';






const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX;  // Replace with your actual Mapbox access token




// const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GoogleMapsAPIKey; // eslint-disable-line
const TILESET_URL = 'https://tile.googleapis.com/v1/3dtiles/root.json?key=' + process.env.REACT_APP_GoogleMapsAPIKey;

// Constants for geographic calculations
const EARTH_CIRCUMFERENCE = 40075017; // in meters
const degreeToRadians = (degree) => degree * (Math.PI / 180);



const debouncedShow3DMap = debounce((deckRef, setShow3D) => {

    show3DMap(deckRef, setShow3D);
}, 100); // 250 milliseconds - adjust this value based on your needs

async function show3DMap(deckRef, setShow3D) {
    var heightOverTerrain = await heightAboveTerrain(deckRef)

    if (heightOverTerrain > 2000) {
        setShow3D(false)
    } else {
        setShow3D(true)
    }
}

function isNumber(value) {
    return typeof value === 'number' && !isNaN(value);
}

async function heightAboveTerrain(deckRef) {
    if (!deckRef) {
        return 3000
    }

    var altitude = zoomToAltitude(deckRef?.current?.deck?.viewState['default-view']?.zoom)
    var elevation = await axios.get(process.env.REACT_APP_DATABASE +
        "api/getElevationLocal?longitude=" +
        deckRef?.current?.deck?.viewState['default-view']?.longitude +
        "&latitude=" +
        deckRef?.current?.deck?.viewState['default-view']?.latitude, { timeout: 2000 }).catch((error) => {
            console.log(error)

        })

    var returnValue = altitude - elevation?.data?.elevation
    if (isNumber(returnValue)) {
        return returnValue
    }
    else {
        return 100000
    }
}




function zoomToAltitude(zoom) {
    // This is a rough estimation and needs to be adjusted according to your specific use case
    return 10000 / Math.pow(2, zoom - 10);
}

const NewDeck = forwardRef(({ data, selectedItems, viewState, lastViewState, setLastViewState, setViewState, toggleItem, toggleClusterHighlights, clusterHighlights, searchCircle, setCircleCenter, isOpen }, ref) => {



    const [zoom, setZoom] = useState(12); // Only track zoom level
    const [latitude, setLatitude] = useState(37); // Only track zoom level

    const [cities, setCities] = useState(null)
    const [streets, setStreets] = useState(null)
    const deckRef = useRef();
    const [show3D, setShow3D] = useState(false)





    const [clusters, setClusters] = useState([]);

    const [supercluster, setSupercluster] = useState(null);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setViewState((prevState) => ({
                        ...prevState,
                        longitude: position.coords.longitude,
                        latitude: position.coords.latitude
                    }));
                    setCircleCenter([position.coords.longitude, position.coords.latitude])
                },
                (error) => {
                    console.error('Error getting user location:', error);
                }
            );
        }
    }, []);
    useEffect(() => {
        const cluster = new Supercluster({
            radius: 40,
            maxZoom: 16,
            map: (properties) => ({
                names: [properties.Name], // Start with an array containing the Name of the point
                websites: [properties.Website] // Start with an array containing the Website of the point
            }),
            reduce: (accumulated, props) => {
                // Combine the names and websites from each point into the cluster
                accumulated.names = accumulated.names.concat(props.names);
                accumulated.websites = accumulated.websites.concat(props.websites);
            }
        });

        // Load initial data into Supercluster
        cluster.load(data); // Assuming data is a GeoJSON FeatureCollection
        setSupercluster(cluster);
    }, [data]);

    useEffect(() => {
        if (supercluster) {
            const bbox = [-180, -85, 180, 85]; // Full world bounding box
            const clusters = supercluster.getClusters(bbox, zoom);
            setClusters(clusters);
        }
    }, [zoom, supercluster]);

    const debouncedStateSets = debounce((viewState) => {
        setZoom(viewState.zoom);
        setLastViewState(viewState)
        setLatitude(viewState.latitude);
    }, 100); // 250 milliseconds - adjust this value based on your needs

    // Calculate rotation angle in degrees (counter-clockwise)
    const getRotationAngle = (bearing) => {
        return -bearing; // Negative to compensate the map's bearing
    };

    const handleButtonPress = () => {   // Calculate the bounds
        let minLat = Infinity, minLng = Infinity, maxLat = -Infinity, maxLng = -Infinity;

        data.forEach(point => {
            const [lng, lat] = point.geometry.coordinates;
            if (lat < minLat) minLat = lat;
            if (lat > maxLat) maxLat = lat;
            if (lng < minLng) minLng = lng;
            if (lng > maxLng) maxLng = lng;
        });

        // Ensure the bounds are valid
        if (minLat !== Infinity && maxLat !== -Infinity && minLng !== Infinity && maxLng !== -Infinity) {
            const bounds = {
                northeast: { latitude: maxLat, longitude: maxLng },
                southwest: { latitude: minLat, longitude: minLng }
            };

            // Use `fitBounds` to calculate the center and zoom to fit the bounds
            const { longitude, latitude, zoom } = fitBounds({
                width: window.innerWidth / 3, // Width of the map container in pixels
                height: window.innerHeight / 2, // Height of the map container in pixels
                bounds: [[minLng, minLat], [maxLng, maxLat]],
                padding: 40
            });

            setViewState({
                longitude,
                latitude,
                zoom,
                pitch: 60,
                transitionDuration: 2000, // Duration in milliseconds
                transitionInterpolator: new FlyToInterpolator(),
                // transitionEasing: d3.easeCubic  // Optional easing function
            });
        }
    }



    useImperativeHandle(ref, () => ({
        handleButtonPress,
        setViewState
    }));

    // Debounced action

    const debouncedUpdate = useCallback(debounce(() => {
        if (deckRef.current) {
            const currentViewState = deckRef.current.deck.viewState['default-view'];
            const viewport = calculateBounds(deckRef);

            if (show3D && viewport && currentViewState && currentViewState.zoom > 10) {
                getCities(viewport, setCities);
                getStreets(viewport, setStreets);
            }
        }
    }, 1000), [show3D, deckRef]); // Include all dependencies here that are used inside 
    function getCitySvgUrl(cityName, backgroundColor = 'rgba(100,155,100,255)', textColor = 'white') {
        const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="200" height="50">
        <rect rx="15" ry="15" width="100%" height="100%" fill="${backgroundColor}" />
        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="${textColor}" font-size="20" font-family="Gill Sans, sans-serif">${cityName}</text>
    </svg>`;

        // Encode the SVG string by first encoding URI components
        const uriEncodedSvg = (svg);
        // Convert encoded URI component into base64
        const base64EncodedSvg = btoa(uriEncodedSvg);

        return `data:image/svg+xml;base64,${base64EncodedSvg}`;
    }

    function isFreeway(name) {
        return /^(I|US)\s+\d+/.test(name); // Matches "I " or "US " followed by numbers
    }
    function getStreetSignSvgUrl(streetName, backgroundColor = 'black', textColor = 'white') {
        const freeway = isFreeway(streetName);
        const svgWidth = 220;
        const svgHeight = 60;
        const circleRadius = 2 * Math.min(svgWidth, svgHeight) / 2 - 10; // Radius is half of the lesser dimension minus some padding

        const backgroundShape = freeway
            ? `<circle cx="${svgWidth / 2}" cy="${svgHeight / 2}" r="${circleRadius}" fill="#1E90FF"/>`
            : `<rect rx="10" ry="10" width="100%" height="100%" fill="${backgroundColor}" />`;

        const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${svgWidth}" height="${svgHeight}">
        ${backgroundShape}
        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="${textColor}" font-size="20" font-family="Arial, sans-serif" font-weight="bold">
            ${isFreeway(streetName) ? preprocessStreetNameI(streetName) : preprocessStreetName(streetName)}
        </text>
    </svg>`;

        return `data:image/svg+xml;base64,${btoa(svg)}`;
    }


    function preprocessStreetNameI(streetName) {
        const maxLength = 8; // Define the maximum length of the street name
        if (streetName.length > maxLength) {
            // If the length of the street name is greater than the maximum length, truncate and add ellipsis
            return streetName.substring(0, maxLength - 3) + '...';
        }
        // If the length is within the limit, return the original street name
        return streetName;
    }

    function preprocessStreetName(streetName) {
        const maxLength = 15; // Define the maximum length of the street name
        if (streetName.length > maxLength) {
            // If the length of the street name is greater than the maximum length, truncate and add ellipsis
            return streetName.substring(0, maxLength - 3) + '...';
        }
        // If the length is within the limit, return the original street name
        return streetName;
    }
    const citiesData = cities ? cities.map(feature => {


        return (
            {
                position: feature.geometry.coordinates,
                name: feature.properties.name,
                icon: getCitySvgUrl(feature.properties.name)
            }
        )
    }
    )
        : []

    const streetsData = streets && streets.length ? streets.map(feature => {


        return (
            {
                position: feature.geometry.coordinates,
                name: feature.name,
                icon: getStreetSignSvgUrl(preprocessStreetName(feature.name))
            }
        )
    }
    )
        : []


    function createFrameSvg(width, height, selected) {
        const aspectRatio = height / width;
        const svgWidth = 120; // Base width for the SVG
        const svgHeight = svgWidth * aspectRatio; // Height adjusted by the aspect ratio
        const strokeWidth = Math.max(1, Math.min(svgWidth, svgHeight) * 0.05); // Adjust the stroke width proportionally, 5% of the smaller dimension

        const fillColor = selected ? 'yellow' : 'red'; // Conditional fill color based on selection
        return `<svg width="${svgWidth}" height="${svgHeight}" xmlns="http://www.w3.org/2000/svg">
        <rect x="${strokeWidth / 2}" y="${strokeWidth / 2}"
              width="${svgWidth - strokeWidth}" height="${svgHeight - strokeWidth}"
              rx="10" ry="10"
              style="fill:rgba(255,255,255,0.5); stroke:${fillColor}; stroke-width:${strokeWidth};" />
    </svg>`;
    }


    const pixelSizeToDegrees = (widthInPixels, heightInPixels, latitude, targetWidthMeters = 50) => { // Increased size for better visibility

        const metersPerDegree = Math.cos(degreeToRadians(latitude)) * EARTH_CIRCUMFERENCE / 360;
        const targetWidthDegrees = targetWidthMeters / metersPerDegree;
        const aspectRatio = heightInPixels / widthInPixels;
        const targetHeightDegrees = targetWidthDegrees * aspectRatio;
        return { width: targetWidthDegrees, height: targetHeightDegrees };
    };

    // Function to calculate size based on zoom level and geographic width
    const calculateSizeFromZoom = (widthInDegrees) => {
        // Base size calculation - adjust constants as necessary to fit your use case
        const zoomLevelAdjustmentFactor = 0.1; // Adjust this factor based on trial and error for best visual effect


        return widthInDegrees * 111320 * Math.cos(degreeToRadians(latitude)) * zoomLevelAdjustmentFactor * Math.pow(2, zoom - 10);
    };


    function createSvgIcon(width, height, content, selected) {
        const svgWidth = 100; // Base width for SVG
        const svgHeight = svgWidth * (height / width); // Adjust height based on aspect ratio

        const svgContent = selected ? `<svg width="${svgWidth}" height="${svgHeight}" xmlns="http://www.w3.org/2000/svg">
        <rect x="5" y="5" width="${svgWidth - 10}" height="${svgHeight - 10}" rx="10" ry="10" style="fill:rgba(255,255,255,0.8); stroke:yellow; stroke-width:5;"/>
        ${content}
    </svg>`:
            `<svg width="${svgWidth}" height="${svgHeight}" xmlns="http://www.w3.org/2000/svg">
        <rect x="5" y="5" width="${svgWidth - 10}" height="${svgHeight - 10}" rx="10" ry="10" style="fill:rgba(255,255,255,0.8); stroke:red; stroke-width:5;"/>
        ${content}
    </svg>`;


        return svgContent;
    }

    function createSvgClusterIcon(width, height, content) {
        const svgWidth = 100; // Base width for SVG
        const svgHeight = svgWidth * (height / width); // Adjust height based on aspect ratio

        const svgContent = `<svg width="${svgWidth}" height="${svgHeight}" xmlns="http://www.w3.org/2000/svg">
        <rect x="5" y="5" width="${svgWidth - 10}" height="${svgHeight - 10}" rx="10" ry="10" style="fill:rgba(255,255,255,0.8); stroke:green; stroke-width:5;"/>
        ${content}
    </svg>`;

        return svgContent;
    }








    const dataPictures = data?.filter(feature =>
        feature.properties.images?.some(image => image?.img?.length > 0 && image.width && image.height)
    ).flatMap(feature =>
        feature.properties.images.map(image => {

            if (image.img.length > 0 && image.width > 0 && image.height > 0) {
                var index = selectedItems.findIndex(item => item.properties.placeId === feature.properties.placeId);
                var selected = false
                if (index > -1) {

                    selected = true
                }



                const [lng, lat] = feature?.geometry?.coordinates;
                const { width, height } = pixelSizeToDegrees(image.width, image.height, lat, 50);
                const svgString = createFrameSvg(width, height, selected);
                const encodedSvg = encodeURIComponent(svgString);
                const svgUri = `data:image/svg+xml,${encodedSvg}`;



                return {
                    position: [lng, lat, 50],
                    icon: image.img,
                    frameIcon: svgUri,
                    size: width,
                    aspectRatio: height / width,
                    width: image.width,
                    height: image.height,
                    properties: feature.properties,
                    selected: selected,
                    geometry: {
                        type: 'Point',
                        coordinates: [lng, lat, 50],
                    }
                };
            }
        }).filter(item => item !== null)  // This should be redundant now but is kept for safety.
    );

    // const datapoint = {
    //     "type": "Feature",
    //     "properties": { "name": "Ashby (ASHB)" },
    //     "geometry": { "type": "Point", "coordinates": [-122.26978, 37.853024] }
    // },



    // Ensure you remove the redundant null return since every path now results in a defined object

    function preloadImage(url) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = () => reject(new Error(`Failed to load image at ${url}`));
            img.src = url;
        });
    }

    async function setupLayer(imageUrl) {
        try {
            await preloadImage(imageUrl);
            // Proceed to add the image to the layer since it's loaded successfully
        } catch (error) {
            console.error(error);
            // Handle failed image loading (e.g., use a fallback image or skip adding the layer)
        }
    }

    // Generate data for letters using the same logic as images
    const dataLetters = data.map(feature => {
        const [lng, lat, z] = feature.geometry.coordinates;

        if (((feature.properties.images.length === 0) || feature?.properties?.images?.[0]?.error) && feature.properties.Name) {
            var index = selectedItems.findIndex(item => item.properties.placeId === feature.properties.placeId);
            var selected = false
            if (index > -1) {

                selected = true
            }
            const firstLetter = feature.properties.Name[0].toUpperCase();
            const { width, height } = pixelSizeToDegrees(100, 100, lat, 50); // Standard dimensions for letters
            const letterContent = selected ? `<text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="red" font-size="40" font-family="Arial">${firstLetter}</text>` :
                `<text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="red" font-size="40" font-family="Arial">${firstLetter}</text>`;
            const svgString = createSvgIcon(width, height, letterContent, selected);
            const encodedSvg = encodeURIComponent(svgString);
            const svgUri = `data:image/svg+xml,${encodedSvg}`;

            return {
                position: [lng, lat, 50],
                icon: svgUri,
                size: width,
                aspectRatio: height / width,
                width: 100,
                height: 100,
                properties: feature.properties,
                selected: selected,
                geometry: {
                    type: 'Point',
                    coordinates: [lng, lat, 50],
                }
            };
        }
    }).filter(item => item); // Ensure only items with SVG are kept

    const clusterIcons = clusters.map(cluster => {
        const [lng, lat] = cluster.geometry.coordinates;
        const pointCount = cluster.properties.point_count;
        const { width, height } = pixelSizeToDegrees(100, 100, lat, 10); // Assuming a zoom level for scale calculation
        const numberContent = `<text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="green" font-size="40" font-family="Arial">${pointCount}</text>`;
        const svgString = createSvgClusterIcon(width, height, numberContent);
        const encodedSvg = encodeURIComponent(svgString);
        const svgUri = `data:image/svg+xml,${encodedSvg}`;

        if (pointCount > 1) {

            return {
                position: [lng, lat, 50], // Position includes a height offset
                icon: svgUri,
                size: width,
                aspectRatio: height / width,
                width: 100,
                height: 100,
                properties: cluster.properties
            };
        }
    }).filter(item => item);



    // const streetPostLayer = new ColumnLayer({
    //     id: 'line-layer',
    //     data: streetsLinesOnMap,
    //     diskResolution: 9,
    //     radius: 1,
    //     extruded: true,
    //     extensions: [new TerrainExtension()],
    //     terrainDrawMode: 'offset',
    //     pickable: false,
    //     elevationScale: .99,
    //     getPosition: d => d.geometry.coordinates,
    //     getFillColor: d => [255, 0, 0],
    //     getLineColor: [0, 0, 0],
    //     getElevation: d => d.elevation  //
    // })





    const citiesLayer = new IconLayer({
        id: 'city-icon-layer',
        data: citiesData,
        visible: show3D,
        getIcon: d => {

            return ({
                url: d.icon,
                width: 200,
                height: 50,
                anchorY: 25  // Adjust based on your SVG layout
            })
        },
        getPosition: d => d.position,
        getSize: d => 40,
        sizeScale: 1,
        extensions: [new TerrainExtension()],
        terrainDrawMode: 'offset',
        billboard: true

    })

    const streetsLayer = new IconLayer({
        id: 'streets-icon-layer',
        data: streetsData,
        visible: show3D,
        getIcon: d => {

            return ({
                url: d.icon,
                width: 200,
                height: 50,
                anchorY: 25  // Adjust based on your SVG layout
            })
        },
        getPosition: d => {
            // Check if the feature is a freeway
            if (isFreeway(d.name)) {
                // If it's a freeway, return a new position array with the z value increased by 30
                return [d.position[0], d.position[1], d.position[2] + 30];
            }
            // Otherwise, return the original position
            return d.position;
        },

        getSize: d => {
            // Check if the feature is a freeway
            if (isFreeway(d.name)) {
                // If it's a freeway, return a new position array with the z value increased by 30
                return 30;
            }
            // Otherwise, return the original position
            return 20;
        },

        sizeScale: 1,
        extensions: [new TerrainExtension()],
        terrainDrawMode: 'offset',
        // billboard: false

    })


    const columnLayer = new ColumnLayer({
        id: 'column-layer',
        data: streetsData,
        visible: show3D,
        diskResolution: 12,  // Defines the number of segments to use for the circular base, 12 gives a relatively smooth circle
        radius: 1,  // Radius of the columns at the base in meters
        extruded: true,  // Enables extrusion which is necessary for 3D columns
        pickable: true,  // Allows the layer to respond to mouse events
        elevationScale: 1,  // Adjust the height scaling if needed
        getPosition: d => [d.position[0], d.position[1], -10],  // Use the same position data as the street icons
        getFillColor: [0, 140, 255],  // Set the color of the columns; adjust as needed
        getElevation: d => {
            if (isFreeway(d.name)) {
                // If it's a freeway, return a new position array with the z value increased by 30
                return d.position[2] + 28;
            }
            return d.position[2] + 8
        },  // Use the z value of the street icons to set the height of the columns
        transitions: {
            getElevation: 500  // Optional: Smooth transition for changes in elevation
        },
        getFillColor: [0, 0, 0]

    });

    // Include this layer along with your other layers when initializing or updating your Deck.gl Map



    const googleTiles = new Tile3DLayer({
        id: 'tile-3d-layer',
        data: TILESET_URL,
        // loader: 'i3s',  // or '3d-tiles' depending on your tile format
        // pickable: true,
        pointSize: 10,
        visible: show3D,
        operation: 'terrain+draw',
        // onClick: info => {

        //     alert(JSON.stringify(info))
        // },
        // onTilesetLoad: tileset3d => {
        //     tileset3d.options.onTraversalComplete = selectedTiles => {
        //         const uniqueCredits = new Set();

        //         selectedTiles.forEach((tile, index) => {
        //             const { copyright } = tile.content.gltf.asset;
        //             copyright.split(';').forEach(uniqueCredits.add, uniqueCredits);


        //             // //test - console.log("latlongarray " + index)
        //             // var latlngElevationArray = projector.unproject(tile.boundingVolume.center[0], tile.boundingVolume.center[1], tile.boundingVolume.center[2])
        //             // if (latlngElevationArray[2] > -30) {

        //             //     //test - console.log("entering recursive")
        //             //     recursiveAdd(tile)
        //             // }
        //             // else {
        //             //     // //test - console.log("deleteing")
        //             //     // //test - console.log(theTile)
        //             //     // //test - console.log(selectedTiles[index])
        //             //     selectedTiles.splice(index, 1)
        //             //     // //test - console.log(selectedTiles[index])
        //             // }
        //         });
        //         // setCredits([...uniqueCredits].join('; '));
        //         // //test - console.log(selectedTiles)





        //         // setMapTiles(latlngElevation)
        //         // setSearchResults(searchResults)

        //         return selectedTiles;
        //     };

        //     // //test - console.log("hello world")
        // },
    })



    var circleGeoJSON = []

    if (searchCircle?.Latitude && searchCircle?.Radius) {
        circleGeoJSON = turf.circle([searchCircle.Longitude, searchCircle.Latitude], searchCircle.Radius, {
            steps: 64,
            units: 'meters'
        });
    }


    const svgSearchHere = getCitySvgUrl("Search Area", "orange", "white")



    const textGeoJSON = {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                properties: {
                    text: 'Search Area',
                    icon: svgSearchHere
                },
                geometry: {
                    type: 'Point',
                    coordinates: [searchCircle.Longitude, searchCircle.Latitude, 500000000 / calculateSizeFromZoom(40, zoom)]
                }
            }
        ]
    };

    const searchAreaLabel = new IconLayer({
        id: 'searchLabel-layer',
        data: textGeoJSON.features,
        visible: isOpen,
        getIcon: d => {

            return ({
                url: d.properties.icon,
                width: 200,
                height: 50,
                anchorY: 25  // Adjust based on your SVG layout
            })
        },
        getPosition: d => {
            // Check if the feature is a freeway

            return d.geometry.coordinates;
        },

        getSize: d => 30,

        sizeScale: 1,
        extensions: [new TerrainExtension()],
        terrainDrawMode: 'offset',
        // billboard: false

    })


    // Define the GeoJsonLayer with TerrainExtension
    const circleLayer = new GeoJsonLayer({
        id: 'geojson-circle-layer',
        data: circleGeoJSON,
        pickable: true,
        visible: isOpen,
        stroked: true,
        filled: true,
        lineWidthMinPixels: 2,
        getFillColor: [0, 160, 180, 100],
        getLineColor: [220, 80, 0],
        getLineWidth: 50,
        getPointRadius: 150,
        extensions: [new TerrainExtension()],
        extruded: !show3D,
        getElevation: 1,
        wireframe: true,
        // Additional terrain layer props
        elevationScale: 1,
        material: {
            ambient: 0.1,
            diffuse: 0.6
        },
        updateTriggers: {
            getSize: [isOpen, zoom]
        }

        // terrain: {
        //     meshMaxError: 4,
        //     bounds: [longitude - 1, latitude - 1, longitude + 1, latitude + 1],
        //     elevationData: terrainImage,
        //     elevationDecoder: elevationDecoder,
        //     workerUrl: 'path/to/terrain-worker.js'
        // }
    });

    const pictureLayer = new GeoJsonLayer({
        id: 'icon-layer',
        data: dataPictures.concat(dataLetters) || [],  // Ensuring this layer uses the same data set as the frames
        pickable: true,
        onClick: ({ object, x, y }) => {
            if (object) {
                toggleItem(object);  // Assume each object has a unique ID
            }
        },
        iconBillboard: !show3D ? false : true,
        extensions: [new TerrainExtension()],
        terrainDrawMode: 'offset',
        pointType: 'icon+text',
        iconSizeUnits: 'pixels',
        getIcon: d => {

            return ({
                url: d.icon,
                width: d.width || 100,
                height: d.height || 100
            })
        },
        // getPosition: d => d.position,
        getIconSize: d => {


            if (d.selected) {

                var size = 10 * calculateSizeFromZoom(d.size, zoom)
                return size
            }

            else { return 1.25 * calculateSizeFromZoom(d.size, zoom) }
        },

        getText: d => formatText(d.properties.Name),
        getTextBackgroundColor: [255, 100, 0, 255],
        getTextSize: d => .03 * calculateSizeFromZoom(d.size, zoom),
        textFontSettings: {
            fontSize: 48,  // Base font size
            fontFamily: 'Roboto, sans-serif',
            fontWeight: 'normal',

        },
        getTextColor: [255, 255, 255, 255],
        textBackground: true,
        getTextPixelOffset: d => [0, -.1 * calculateSizeFromZoom(d.size, zoom)],
        iconSizeScale: .1,
        iconSizeMinPixels: 20,
        iconSizeMaxPixels: 70,
        getAngle: show3D ? getRotationAngle(lastViewState?.bearing) : null,
        updateTriggers: {
            getSize: [zoom]
        }
    })
    function formatText(inputText) {
        if (!inputText) return '';
        // Get the first word
        const firstWord = inputText.split(' ')[0];
        // Return the first 8 characters, add an ellipsis if the word is longer
        const formattedText = firstWord.length > 8 ? `${firstWord.substring(0, 8)}...` : firstWord;
        // Add space characters for padding
        return `   ${formattedText}   `;
    }




    const pictureFrameLayer = new IconLayer({
        id: 'picture-frame-layer',
        data: dataPictures || [],  // Use data that includes pictures with frames
        pickable: true,
        onClick: ({ object, x, y }) => {
            if (object) {
                toggleItem(object);  // Assume each object has a unique ID
            }
        },
        billboard: !show3D ? (false) : true,
        extensions: [new TerrainExtension()],
        terrainDrawMode: 'offset',
        getIcon: d => {

            return ({
                url: d.frameIcon,
                width: d.width || 100,  // Adjust based on the actual image dimensions
                height: d.height || 100
            })
        },
        getPosition: d => d.position,
        getSize: d => {
            if (d.selected) {

                var size = 10 * calculateSizeFromZoom(d.size, zoom)
                return size
            }

            else { return 2 * calculateSizeFromZoom(d.size, zoom) }
        }, // Adjust size based on zoom
        sizeScale: .1,
        getAngle: !show3D ? getRotationAngle(lastViewState?.bearing) : null,
        sizeMinPixels: 40,
        sizeMaxPixels: 100,
    })




    var layers = [
        citiesLayer,
        streetsLayer,
        columnLayer,
        googleTiles,
        circleLayer,
        searchAreaLabel,


        pictureFrameLayer,
        pictureLayer,



        ,

        new IconLayer({
            id: 'icon-cluster-layer',
            data: clusterIcons || [], // Filter out single clusters
            pickable: true,
            extensions: [new TerrainExtension()],
            terrainDrawMode: 'offset',
            billboard: !show3D ? false : true,
            getIcon: d => {
                // Check if the current icon's properties match the selected properties

                return {
                    url: d.icon,
                    width: d.width || 200,
                    height: d.height || 200
                };
            },
            getPosition: d => {
                const coords = d.position;
                const baseZ = 50; // Base elevation for the icons
                const zoomFactor = 20; // Adjust this factor based on desired effect
                return [coords[0], coords[1], 100]; // Set the Z coordinate to 60
            },
            getSize: d => {
                if (zoom < 15) {
                    return 20
                }
                else {
                    if (isDeepEqual(d?.properties, clusterHighlights)) {
                        return .1 * calculateSizeFromZoom(d.size, zoom);; // Optionally set size to 0, although getIcon returning null should suffice
                    }
                    return calculateSizeFromZoom(d.size, zoom);
                }
            },
            onClick: d => {
                toggleClusterHighlights(d.object.properties);
            },
            sizeScale: 2,
            sizeMinPixels: 0,
            getAngle: !show3D ? getRotationAngle(lastViewState?.bearing) : null,
            sizeMaxPixels: 200,
            updateTriggers: {
                getSize: [zoom],
                getAngle: lastViewState?.bearing
            }
        }),

        new ColumnLayer({
            id: 'properties-lines',
            data: dataPictures?.map((data) => { return ({ position: [data.position[0], data.position[1], -10] }) }) || [],
            diskResolution: 9,
            radius: 2,
            extensions: [new TerrainExtension()],
            terrainDrawMode: 'offset',
            extruded: true,
            pickable: false,
            elevationScale: 1,
            getPosition: d => d.position,
            getFillColor: d => [255, 50, 50],
            getLineColor: [0, 0, 0],
            getElevation: d => 50//d.elevationHeight
        }),
        new ColumnLayer({
            id: 'properties-letters',
            data: dataLetters?.map((data) => { return ({ position: [data.position[0], data.position[1], -10] }) }) || [],
            diskResolution: 9,
            radius: 2,
            extensions: [new TerrainExtension()],
            terrainDrawMode: 'offset',
            extruded: true,
            pickable: false,
            elevationScale: 1,
            getPosition: d => d.position,
            getFillColor: d => [255, 50, 50],
            getLineColor: [0, 0, 0],
            getElevation: d => 50//d.elevationHeight
        }),


        ,

    ];


    const handleViewStateChange = ({ viewState, interactionState, oldViewState }) => {
        debouncedStateSets(viewState)
        debouncedShow3DMap(deckRef, setShow3D)


        if (!interactionState.isZooming && !interactionState.isPanning && !interactionState.isRotating) {

            debouncedUpdate();
        }
    };

    const handlePitchChange = (event, newValue) => {
        if (lastViewState) {
            setViewState({ ...lastViewState, pitch: newValue });
            setLastViewState({ ...lastViewState, pitch: newValue });
        } else {
            setViewState({ ...viewState, pitch: newValue });
            setLastViewState({ ...lastViewState, pitch: newValue });
        }

    };



    // Update circle center when the map is clicked
    const handleClick = event => {

        const { coordinate } = event;
        setCircleCenter(coordinate);
    };

    return (
        <>

            <DeckGL
                ref={deckRef}
                initialViewState={viewState}

                onViewStateChange={handleViewStateChange}
                controller={true}
                layers={layers}
                getCursor={props => 'grab'}
                onClick={handleClick}
                views={
                    new MapView({
                      nearZMultiplier: 0.1, // Adjust this value to fix clipping
                      farZMultiplier: 1000     // Adjust far plane if needed
                    })
                  }

            >

                <Map
                    mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
                    mapStyle="mapbox://styles/schordr/ckvq4te666xrm14lq6wmj1fi0"

                />

            </DeckGL>

            <Box sx={{ position: 'absolute', top: '50%', left: 10, transform: 'translateY(-50%)' }}>
                <Typography sx={{ color: 'white', marginBottom: 1 }}>Tilt</Typography>
                <Slider
                    orientation="vertical"
                    value={viewState.pitch}
                    onChange={handlePitchChange}
                    aria-labelledby="vertical-slider"
                    min={0}
                    max={60}
                    step={1}
                    sx={{ height: 200 }}
                />

            </Box>

        </>
    );
})

export default NewDeck
export async function getStreets(myViewport, setStreets) {
    if (!myViewport) {
        return false;
    }

    var streetsResult = await axios.post(process.env.REACT_APP_DATABASE + "api/getStreets", myViewport).catch((error) => console.log(error))

    if (streetsResult?.data) {
        setStreets(streetsResult.data)
    }
    else {
        // do nothing
    }

}


export async function getCities(myViewport, setCities) {
    if (!myViewport) {
        return false;
    }

    var citiesResult = await axios.post(process.env.REACT_APP_DATABASE + "api/getCities", myViewport).catch((error) => { console.log(error) })

    if (citiesResult?.data) {
        setCities(citiesResult.data)
    }
    else {
        // do nothing
    }

}







export function getAxios(query, controller) {

    return new Promise(async function (resolve, reject) {

        if (controller.controller) {
            controller.controller.abort()
        }

        controller.controller = new AbortController()
        var result = await axios.get(query, {
            signal: controller.controller.signal
        }).catch((error) => {

            // console.log(error)
            console.log("getAxios cancelled")
            reject(error)
        })
        if (controller.controller.signal && controller.controller.signal.aborted) {
            reject("aborted")
            return false
        }

        resolve(result)

    })
}


const calculateBounds = (deckRef) => {
    if (deckRef?.current?.deck?.viewState?.['default-view']) {

        const { width, height, longitude, latitude, zoom } = deckRef?.current?.deck?.viewState?.['default-view'];

        const viewport = new WebMercatorViewport({ width, height, longitude, latitude, zoom });
        const newBounds = viewport.getBounds(); // [westLng, southLat, eastLng, northLat]

        return ({
            _ne: {
                lat: newBounds[3],
                lng: newBounds[2]
            },
            _sw: {
                lat: newBounds[1],
                lng: newBounds[0]
            }
        });
    }
    {
        return ({
            _ne: {
                lat: 38,
                lng: -120
            },
            _sw: {
                lat: 38,
                lng: -120
            }
        });
    }
};

