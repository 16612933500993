function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function   TabsCB({
  tabHighLight,
  setTabHighLight,
  tabs,
  dataIn,
  setData,
  update,
  editMode,
  contactId,
  type
}) {
  return (
    <div>
      <div className="sm:hidden">
        {/* <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label> */}
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        {/* <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
            defaultValue={tabs.find((tab) => tab.current).name}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select> */}
      </div>
      {/* <div className="hidden sm:block"> */}
      <div>
        {/* <div className="border-b border-gray-200"> */}
        <div>
          <nav
            className="-mb-px px-6 overflow-auto flex space-x-2 justify-between w-full sm:w-2-third "
            aria-label="Tabs"
          >
            {tabs.map((tab, index) => {
              tabHighLight === tab.name
                ? (tab.current = true)
                : (tab.current = false);
              return (
                
                <div className="w-40 flex justify-center " key={"tab"+index}>
                <a
                  key={tab.name}
                  onClick={() => {
                    tab.current = true;
                    setTabHighLight(tab.name);
                    if (editMode === "Edit") {
                      saveData(dataIn, setData, update, contactId, type);
                    }
                  }}
                  className={classNames(
                    tab.current
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 hover:cursor-pointer",
                    "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  <tab.icon
                    className={classNames(
                      tab.current
                        ? "text-indigo-500"
                        : "text-gray-400 group-hover:text-gray-500",
                      "-ml-0.5 mr-2 h-5 w-5"
                    )}
                    aria-hidden="true"
                  />
                  <span className="text-xs px-2">{tab.name}</span>
                </a>

                </div>
                
              );
            })}
          </nav>
        </div>
      </div>
    </div>
  );
}

function saveData(dataIn, setData, update, contactId, type) {
  var entries = [];
  for (const [key, value] of Object.entries(dataIn)) {
    entries.push({ fieldName: `${key}`, fieldValue: value });
  }
  var journalArray = [];
  entries.map((entry) => {
    if (document.getElementById(entry.fieldName)) {
      

      if (
        !(
          document.getElementById(entry.fieldName).value === "" &&
          !dataIn[entry.fieldName]
        )
      ) {
        if (
          dataIn[entry.fieldName]?.toString() !==
          document.getElementById(entry.fieldName).value
        ) {
        
          if (type==="Avail"){
            if (entry.fieldName.indexOf("Date") >= 0) {
              var date1 = new Date(dataIn[entry.fieldName]);
              var date2 = new Date(
                document.getElementById(entry.fieldName).value
              );
              var DifferenceTime = date2.getTime() - date1.getTime();

              var DifferenceDays = DifferenceTime / (1000 * 3600 * 24);

              if (-10 < DifferenceDays < 10) {
                //do nothing its close
              } else {
                journalArray.push({
                  availId: dataIn.id,
                  AuthorUserID: contactId,
                  Field: entry.fieldName,
                  OldValue: dataIn[entry.fieldName],
                  NewValue: document.getElementById(entry.fieldName).value,
                });
              }
            }
            else {
          journalArray.push({
            availId: dataIn.id,
            AuthorUserID: contactId,
            Field: entry.fieldName,
            OldValue: dataIn[entry.fieldName],
            NewValue: document.getElementById(entry.fieldName).value,
          });}}
          else {
            if (entry.fieldName.indexOf("Date") >= 0) {
              var date1 = new Date(dataIn[entry.fieldName]);
              var date2 = new Date(
                document.getElementById(entry.fieldName).value
              );
              var DifferenceTime = date2.getTime() - date1.getTime();

              var DifferenceDays = DifferenceTime / (1000 * 3600 * 24);

              if (-10 < DifferenceDays < 10) {
                //do nothing its close
              } else {
                journalArray.push({
                  buildingId: dataIn.id,
                  AuthorUserID: contactId,
                  Field: entry.fieldName,
                  OldValue: dataIn[entry.fieldName],
                  NewValue: document.getElementById(entry.fieldName).value,
                });
              }
            } else {
              journalArray.push({
                buildingId: dataIn.id,
                AuthorUserID: contactId,
                Field: entry.fieldName,
                OldValue: dataIn[entry.fieldName],
                NewValue: document.getElementById(entry.fieldName).value,
              });
            }
          }
        }
      }
    

      if ((!document.getElementById(entry.fieldName).value) || (document.getElementById(entry.fieldName).value === "")) {
        dataIn[entry.fieldName] = null
      }
      else {
        dataIn[entry.fieldName] = updateJson(
          dataIn[entry.fieldName],
          document.getElementById(entry.fieldName).value
        );
      }
    }
  });
  var newData = { ...dataIn };
  setData(newData);
  update(dataIn, journalArray);
}
function updateJson(jsonFieldIn, reqValueIn) {
  if (reqValueIn !== "") {
    if (reqValueIn === -1) {//  I think this entire function is meaningless  come back to this later
      jsonFieldIn = null;
    } else {
      jsonFieldIn = reqValueIn;
    }
  }
  // return jsonFieldIn;
  return reqValueIn;
}
