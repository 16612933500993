// truncateText.js

/**
 * Truncates a given text to the specified maximum length.
 * If the text exceeds the maximum length, it appends an ellipsis.
 *
 * @param {string} text - The text to truncate.
 * @param {number} maxLength - The maximum allowed length.
 * @returns {string} - The truncated text with ellipsis if needed.
 */
export const truncateText = (text, maxLength) => {
    if (!text) return "";
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength - 3) + "...";
  };
  