import React from "react";
import { Box, Typography, Button, Grid, Divider } from "@mui/material";
import ConfirmInfo from "../../Common/ConfirmInfo";
import WebsitesSection from "./WebsitesSection";
import { mobileAndTabletCheck } from "../Deck";
// Helper function to format numbers
const ConvertNumberFormat = (number) => {
  return new Intl.NumberFormat("en-US").format(number);
};

const PropertyDetails = ({
  PropertyType,
  CampusName,
  PropertyName,
  StreetNumber,
  StreetName,
  City,
  property,
  confirm,
  setShowConfirm,
  showConfirm,
  contactId,
}) => {
  return (
    <Box px={2}>
      <Grid container spacing={2}>
        {/* Left Side: Property Information */}
        <Grid item xs={12} md={8}>
          <Box display="flex" flexDirection="column" flexGrow={1}>
            {/* Property Title */}
            <Box textAlign="left" mb={1}>
              <Typography variant="h6" fontWeight="bold">
                {PropertyType}
              </Typography>
              <Typography variant="subtitle1">{CampusName}</Typography>
              <Typography variant="subtitle2">{PropertyName}</Typography>
            </Box>

            {/* Address and Property Size */}
            <Box>
              <Typography variant="body2" fontWeight="medium">
                {StreetNumber} {StreetName}, {City}
              </Typography>
            
              {property.Size && window.innerWidth > 760 ? (
                <Box mt={1}>
                  {property.OSM ? (
                    <Typography variant="body2">
                      Est. Size: {ConvertNumberFormat(property.Size / 1.5)} -{" "}
                      {ConvertNumberFormat(property.Size)} Square Foot Building
                    </Typography>
                  ) : (
                    <Typography variant="body2">
                      {ConvertNumberFormat(property.Size)} Square Foot Building
                    </Typography>
                  )}
                </Box>
              ):(<></>)}
            </Box>

            {/* Confirm Button */}
            {confirm && (
              <Box py={2}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={(event) => {
                    event.stopPropagation();
                    setShowConfirm(true);
                  }}
                >
                  Confirm This Property
                </Button>
              </Box>
            )}

            {/* Confirm Dialog */}
            <ConfirmInfo
              open={showConfirm}
              setOpen={setShowConfirm}
              contactId={contactId}
              PropertyInfo={property}
            />
          </Box>
        </Grid>

        {/* Right Side: Websites Section */}
        <Grid item xs={12} md={4}>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <WebsitesSection
              websites={property.websites}
              mobileAndTabletCheck={mobileAndTabletCheck}
              truncateLimit={8}

            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PropertyDetails;
