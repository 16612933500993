// billboardLayer.js

// Import necessary classes and extensions
import { GeoJsonLayer } from "@deck.gl/layers";
import { _TerrainExtension as TerrainExtension } from "@deck.gl/extensions";
import {
  generateBillboardSVG,
  generateBillboardDimensions,
} from "./generateBillboardSVG";
import { getAvailPrice, getCompsPrice } from "../../Pin";
// billboardLayer.js

/**
 * Creates a GeoJsonLayer for billboards with customizable parameters.
 *
 * @param {Object} params - The parameters for the billboard layer.
 * @param {Function} params.getElevationFromZoom - Function to get elevation based on zoom.
 * @param {number} params.currentZoom - Current zoom level.
 * @param {number} params.currentElevation - Current elevation value.
 * @param {Array} params.searchResults - Array of search result objects.
 * @param {Array} params.coords - GeoJSON coordinates data.
 * @param {Function} params.mobileAndTabletCheck - Function to check if device is mobile or tablet.
 * @param {Object} params.ICON_MAPPING - Icon mapping configuration.
 * @param {Function} params.getCookie - Function to retrieve a cookie value.
 * @param {Function} params.getSelectedLocation - Function to get the selected location.
 * @param {Object} params.filters - Filter settings.
 * @param {Function} params.createCookie - Function to create/set a cookie.
 * @param {Function} params.setSelectedLocation - Function to set the selected location.
 * @param {Function} params.scrollToResult - Function to handle scrolling to a result.
 * @param {Object} params.overlay - Overlay object with setProps method.
 * @param {Function} params.recalcLayers - Function to recalculate layers.
 * @param {Function} params.onLayerClick - Function to handle layer click events.
 * @param {Function} params.getTextAvails - Function to get text for availabilities.
 *
 * @returns {GeoJsonLayer} - Configured GeoJsonLayer instance.
 */
export function returnBillBoardLayer({
  getElevationFromZoom,
  currentZoom = 15,
  currentElevation,
  searchResults = [],
  coords,
  mobileAndTabletCheck,

  getCookie,
  selectedLocation,
  filters,
  createCookie,
  setSelectedLocation,
  scrollToResult,
  overlay,
  recalcLayers,
  onLayerClick,
  // getTextAvails,
}) {
  // Determine camera elevation based on zoom
  const camElevation = getElevationFromZoom(currentZoom);

  // Determine variable elevation
  let theVarElevation =
    currentElevation > 0
      ? currentElevation
      : searchResults.length > 0
      ? searchResults[0].Elevation
      : 5;

  if (!theVarElevation) {
    theVarElevation = 10;
  }

  const distanceFromGround = camElevation - theVarElevation;

  return new GeoJsonLayer({
    id: "geojson-billboards-avails",
    data: coords,
    // extensions: mobileAndTabletCheck() ? [] : [new TerrainExtension()],
    // terrainDrawMode: mobileAndTabletCheck() ? null : "offset",
    extensions:  [new TerrainExtension()],
    terrainDrawMode:  "offset",
    pointType: "icon",
    updateTriggers: {
      getIcon: [selectedLocation], // Add the selectedLocation dependency here
      getIconSize: [selectedLocation]
    },

    getIcon: (d) => {
      const dimensions = generateBillboardDimensions(
        getTextAvails(d, filters),
        {
          backgroundColor: "blue",
          textColor: "black",
          maxLineWidth: 150,
        },
        d,
        selectedLocation
      );

      return {
        url: generateBillboardSVG(
          getTextAvails(d, filters),
          {
            backgroundColor: "blue",
            textColor: "black",
            maxLineWidth: 150,
          },
          d,
          selectedLocation
        ),
        width: dimensions.width,
        height: dimensions.height,
      };
    },
    iconSizeUnits: "pixels",
    getIconSize: (d) => {
      if (d?.id === selectedLocation?.id) {
        return 80;
      } else {
        return 50;
      }
    },

    iconBillboard: true,

    pickable: true,
    onClick: (info) => {
      let targetId;
      try {
        // const rawCookie = getCookie("selectedLocation");
        targetId = selectedLocation?.id;
      } catch (error) {
        console.log(error);
      }

      const myNumber = parseInt(info?.object?.id, 10);

      if (myNumber !== targetId) {
        setSelectedLocation(info?.object);
      } else {
        setSelectedLocation(null);
      }

      if (!mobileAndTabletCheck()) {
        scrollToResult(info);
      } else {
        onLayerClick(info);
      }
    },
  });
}

const getTextAvails = (d, filters) => {
  let propertyType = "";
  let campusName = "";
  let price = "";
  let size = "";

  // Extract Property Types
  if (d.avails.length > 0) {
    const types = [...new Set(d.avails.map((avail) => avail.PropertyType))];
    propertyType = types.join(", ");
    // if (propertyType.length > 15) {
    //   propertyType = propertyType.slice(0, 15) + "...";
    // }
  }

  // Extract Campus Name or Property Name
  if (d.properties.PropertyName && d.properties.PropertyName.length > 0) {
    campusName = d.properties.PropertyName;
    // if (campusName.length > 15) {
    //   campusName = campusName.slice(0, 15) + "...";
    // }
  } else if (d.properties.CampusName && d.properties.CampusName.length > 0) {
    campusName = d.properties.CampusName;
    // if (campusName.length > 15) {
    //   campusName = campusName.slice(0, 15) + "...";
    // }
  }

  // Extract Price
  if (
    d.properties.summary.onMarketAvailSF +
      d.properties.summary.offMarketAvailSF >
    0
  ) {
    price = getAvailPrice(d);
    if (price.length > 0) {
      price += " SF/Mo";
    }
  } else if (
    filters.compsCheck &&
    d.properties.summary.onMarketCompsSF +
      d.properties.summary.offMarketCompsSF >
      0
  ) {
    price = getCompsPrice(d);
    if (price.length > 0) {
      price += " SF/Mo";
    }
  }

  // Extract Size
  if (d.properties.Footprint && d.properties.Footprint > 0) {
    size = `${Math.round(d.properties.Footprint / 1000)}K SF Footprint`;
  } else if (d.properties.Size && d.properties.Size > 0) {
    size = `${Math.round(d.properties.Size / 1000)}K SF Building`;
  }

  let availSF;
  const totalAvailSF =
    d.properties.summary.onMarketAvailSF +
    d.properties.summary.offMarketAvailSF;
  if (totalAvailSF > 0) {
    availSF = `${Math.round(totalAvailSF / 1000)}K SF Available`;
  }

  let compSF;

  const totalCompSF =
    d.properties.summary.onMarketCompsSF +
    d.properties.summary.offMarketCompsSF;
  if (totalCompSF > 0) {
    compSF = `${Math.round(totalCompSF / 1000)}K SF Comps`;
  }

  // Return structured JSON
  return {
    PropertyType: propertyType || null,
    CampusName: campusName || null,
    Price: price || null,
    Size: size || null,
    availSF,
    compSF,
  };
};
