// findPeople.js

import axios from 'axios';

/**
 * Checks if the specified key in a JSON object is a non-empty string.
 * @param {Object} json - The JSON object to check.
 * @param {string} key - The key to verify.
 * @returns {boolean} - True if the key exists and is a non-empty string, else false.
 */
function isNonEmptyString(json, key) {
    if (key in json) {
        return typeof json[key] === 'string' && json[key].trim().length > 0;
    }
    return false;
}

/**
 * Extracts the domain from a given URL.
 * @param {string} url - The URL to extract the domain from.
 * @returns {string} - The extracted domain or an empty string if invalid.
 */
function extractDomain(url) {
    if (!url) return '';

    // Ensure the URL includes a protocol
    if (!url.match(/^http?:\/\//i) && !url.match(/^https?:\/\//i)) {
        url = 'http://' + url; // Defaulting to HTTP if no protocol is specified
    }

    try {
        const { hostname } = new URL(url);
        return hostname;
    } catch (error) {
        console.error('Invalid URL:', error);
        return '';
    }
}

/**
 * Converts SVG text to a raster Image object.
 * @param {string} svgText - The SVG content as text.
 * @returns {Promise<Image>} - A promise that resolves to the Image object.
 */
function convertSVGToImage(svgText) {
    return new Promise((resolve, reject) => {
        const svgBlob = new Blob([svgText], { type: "image/svg+xml;charset=utf-8" });
        const url = URL.createObjectURL(svgBlob);
        const img = new Image();
        img.crossOrigin = "Anonymous"; // To handle CORS
        img.onload = () => {
            URL.revokeObjectURL(url);
            resolve(img);
        };
        img.onerror = (err) => {
            URL.revokeObjectURL(url);
            reject(new Error("Failed to convert SVG to Image."));
        };
        img.src = url;
    });
}

/**
 * Loads an image from a URL as a Promise.
 * @param {string} url - The URL of the image to load.
 * @returns {Promise<Image>} - A promise that resolves to the Image object.
 */
function loadImageAsPromise(url) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous"; // To handle CORS
        img.onload = () => resolve(img);
        img.onerror = (err) => reject(new Error(`Failed to load image from ${url}`));
        img.src = url;
    });
}

/**
 * Resizes an image to a square canvas with fixed dimensions while maintaining aspect ratio.
 * @param {Image} image - The image to resize.
 * @param {number} size - The desired size (width and height) of the canvas.
 * @returns {HTMLCanvasElement} - The resized canvas.
 */
function resizeImage(image, size) {
    const canvas = document.createElement("canvas");
    canvas.width = size;
    canvas.height = size;
    const ctx = canvas.getContext("2d");

    // Clear the canvas
    ctx.clearRect(0, 0, size, size);

    // Calculate scaling to maintain aspect ratio
    const aspectRatio = image.width / image.height;
    let drawWidth, drawHeight;
    if (aspectRatio > 1) {
        drawWidth = size - 8; // Adjust padding as needed
        drawHeight = drawWidth / aspectRatio;
    } else {
        drawHeight = size - 8;
        drawWidth = drawHeight * aspectRatio;
    }

    // Draw the original image centered
    ctx.drawImage(
        image,
        (size - drawWidth) / 2,
        (size - drawHeight) / 2,
        drawWidth,
        drawHeight
    );

    // Verify canvas dimensions
    if (canvas.width !== size || canvas.height !== size) {
        throw new RangeError(
            `Canvas dimensions mismatch: expected ${size}x${size}, got ${canvas.width}x${canvas.height}`
        );
    }

    return canvas;
}


/**
 * Finds people based on the search method and provided parameters.
 * @param {Object} params - The parameters required for the search.
 * @param {string} params.searchMethod - The method of search ('name' or 'domain').
 * @param {boolean} params.extensive - Whether to perform an extensive search.
 * @param {Object} params.companyIn - The company object.
 * @param {string} params.companyName - The name of the company.
 * @param {string} params.domain - The domain to search within.
 * @param {string} params.contactName - The contact name.
 * @param {string} params.city - The city for location-based search.
 * @param {string} params.state - The state for location-based search.
 * @param {string} params.contactId - The contact ID.
 * @param {string} params.keyWords - Keywords for the search.
 * @param {Function} params.setLoading - Function to set the loading state.
 * @param {Function} params.setEmailResult - Function to set the email result state.
 * @param {Function} params.setContactResults - Function to set the contact results state.
 * @param {Function} params.setShowContactResults - Function to control the visibility of contact results.
 * @param {Object} params.contactIn - Additional contact information.
 * @returns {Promise<void>}
 */
export async function findPeople({
    searchMethod,
    extensive,
    companyIn,
    companyName,
    domain,
    contactName,
    city,
    state,
    contactId,
    keyWords,
    contactIn,
    setLoading,
    setEmailResult,
    setContactResults,
    setShowContactResults
} = {}) { // Added default parameter to prevent undefined
    if (searchMethod === 'name') {
        setLoading(true);
        await searchEmail({
            company: companyIn,
            companyName,
            domain,
            contactName,
            city,
            state,
            contactId,
            contactIn,
            setLoading,
            setEmailResult,
            setContactResults
        });
        setLoading(false);
        setShowContactResults(true);
    }
    else if (searchMethod === 'domain') {
        setLoading(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_DATABASE}api/findPeopleForOfficeLocation`, {
                company: companyIn || { Name: companyName }, // Ensure company object
                City: city,
                State: state,
                keyWords: keyWords,
                contactId: contactId,
                extensive: extensive || false
            });

            setLoading(false);

            if (response?.data) {
                setContactResults(response.data);
                setShowContactResults(true);
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }
}
/**
 * Searches for an email based on provided parameters.
 * @param {Object} params - The parameters required for the email search.
 * @param {Object} params.company - The company object.
 * @param {string} params.companyName - The name of the company.
 * @param {string} params.domain - The domain to search within.
 * @param {string} params.contactName - The contact name.
 * @param {string} params.city - The city for location-based search.
 * @param {string} params.state - The state for location-based search.
 * @param {string} params.contactId - The contact ID.
 * @param {Function} params.setLoading - Function to set the loading state.
 * @param {Function} params.setEmailResult - Function to set the email result state.
 * @param {Function} params.setContactResults - Function to set the contact results state.
 * @returns {Promise<void>}
 */
async function searchEmail({
    company,
    companyName,
    domain,
    contactName,
    city,
    state,
    contactId,
    contactIn,
    setLoading,
    setEmailResult,
    setContactResults
}) {
    if (!domain || !contactName) {
        alert('Domain or contact name is missing, or contains errors.');
        return;
    }

    let theCompany = company;
    if (!company || !company.Name) {
        theCompany = { Name: companyName };
    }

    const splitName = contactName.trim().split(' ');
    const firstName = splitName[0];
    const lastName = splitName.slice(1).join(' '); // Handle names longer than two parts.

    if (firstName && lastName) {
        try {
            setLoading(true);

            const response = await axios.post(`${process.env.REACT_APP_DATABASE}api/searchEmail`, {
                firstName,
                lastName,
                domain,
                company: theCompany,
                city,
                state,
                contact: contactIn
            });

            setLoading(false);

            if (response?.data?.contact && isNonEmptyString(response.data.contact, 'Email')) {
                const email = response.data.contact.Email;
                const contact = response.data.contact;
                setEmailResult(`Email found: ${email}`);
                setContactResults(prevContacts => {
                    if (!Array.isArray(prevContacts)) {
                        return [contact];
                    }
                    return [...prevContacts, contact];
                });
            } else if (response?.data?.SDeepSearch && response?.data?.email?.previous) {
                setEmailResult('This email is already in the queue for us to find. Please check back later.');
            }
            else if (response?.data?.SDeepSearch && !response?.data?.email?.previous) {
                setEmailResult('This email is now in the queue for us to find. Please check back later.');
            }
            else {
                setEmailResult('Error finding contact');
            }

        } catch (error) {
            setLoading(false);
            setEmailResult(`Error: ${error.message}`);
        }
    }
    else {
        alert("Please enter a first and last name");
    }
}
