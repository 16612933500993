import Header from "../components/Common/Header";
import Banner from "../components/Common/Banner";
// import LargeCard from "../components/Landing/LargeCard";
// import MediumCard from "../components/Landing/MediumCard";
import Footer from "../components/Common/Footer";

// import LogoutButton from "../components/Auth0/Logout";
// import Profile from "../components/Auth0/Profile";

/* This example requires Tailwind CSS v2.0+ */
import { useEffect, useState } from "react";
import WelcomeSplash from "../components/Common/WelcomeSplash";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";






export default function Home({ user, guest, searchDetailRef }) {

const navigate = useNavigate()
  var output = {};
  document.cookie.split(/\s*;\s*/).forEach(function (pair) {
    pair = pair.split(/\s*=\s*/);
    var name = decodeURIComponent(pair[0]);
    var value = decodeURIComponent(pair.splice(1).join("="));
    output[name] = value;
  });
  // alert(output.checkreroute);

  //test - console.log("in Home");
  const [welcomeSplash, setWelcomeSplash] = useState(false);

  useEffect(() => {
    if (output?.checkreroute === "true") {
      document.cookie = "checkreroute=false;SameSite='none'";
      navigate(output.website.replace(window.location.origin, ""))
    }
  }, [])
  



    return (
      <div  style={{height: '100%', width: '100%', overflow: 'hidden'}}>
        <div>
          {" "}
          {user &&
            user.contact &&
            user.contact.FirstName &&
            user.contact.LastName || (guest === true || guest === 'true' || guest === 'true' || !user.contact.id) ? (
            ""
          ) : (
            <WelcomeSplash contact={user.contact} />
          )}
        </div>
        <Box sx={{width: '100%', }}>

          <nav style={{width: '100%'}} className="fixed z-50 top-0">
            <Header
              placeholderLocation="Cubic Search"
              placeholderSize=""
              icon="home"
              user={user}
            />
          </nav>
          <div style={{
            height: '100%',
            display: 'flex',
            width: '100%', overflow: 'hidden'
          }}>
          
            {/* <div className="flex object-cover justify-center overflow-hidden "> */}
              <Banner
                searchDetailRef={searchDetailRef}
                user={
                  user
                    ? user
                    : {
                      contact: {
                        email: output.email,
                        id: output.contactId,
                      },
                    }
                }
                login={(guest === true || guest === 'true' || guest === 'true' || !user.contact.id) ? "yes" : null}
                className="hidden-mobile " />
       
            
          </div>
        </Box>
      </div>
    );
  
}
