// DeckSplitPanel.jsx

import React, { useState, useEffect, useRef } from "react";
import Split from "react-split";
import { Typography, Box } from "@mui/material";
import InfoCards from "../InfoCards"; // Adjust the import path as needed
import { NavigationControl, Map as DeckMap } from "react-map-gl";
import { mobileAndTabletCheck } from "../Deck";
import "./DeckSplitPanel.css"; // Import the CSS for styling

const DeckSplitPanel = ({
  filterInputs,
  searchResults,
  setSearchResults,
  selectedLocation,
  setSelectedLocation,
  refs,
  confirmIn,
  contactId,
  clusterData,
  removeProperty,
  surveyId,
  setShowSurveys,
  setSaveSurveyOpen,
  initialViewState,
  overlay,
  recalcLayers,
  setReloadOnMove,
  mapRef,
  getZoomFromElevation,
  viewportElevation,
  onMapLoadMapBox,
  handleMoveStart,
  handleOnMoveEnd,
  aboveOrBelow,
  credits,
  MAPBOX_TOKEN,
}) => {
  const [leftWidth, setLeftWidth] = useState(30); // Initial size in percentage
  const isMobile = mobileAndTabletCheck();

  const handleResize = (sizes) => {
    setLeftWidth(sizes[0]);
    // Trigger a window resize event to ensure DeckMap resizes
    window.dispatchEvent(new Event("resize"));
  };

  const handleInfoCardClick = (info) => {
    const theProperty = searchResults.find(
      (theresult) => theresult.id === info
    );
    if (!theProperty) return;

    console.log(theProperty.Latitude);
    console.log(theProperty.Longitude);
    overlay.setProps({ layers: recalcLayers() });
    setReloadOnMove(false);
    mapRef.current.flyTo({
      center: [theProperty.Longitude, theProperty.Latitude],
      zoom: getZoomFromElevation(
        (theProperty.Elevation || viewportElevation) + 1000
      ),
      duration: 2000,
    });
  };

  if (isMobile) {
    // Render only the DeckMap for mobile and tablet devices
    return (
      <div
        style={{
          paddingTop: 4,
          height: "100%", // Ensure full viewport height
          flex: 1,
          paddingRight: 0,
        }}
      >
        <div
          style={{
            backgroundColor: "lightblue",
            borderRadius: 0,
            overflow: "hidden",
            height: "100%",
            width: "100%",
          }}
        >
          <DeckMap
            attributionControl={false}
            onError={(error) => {
              console.log(error);
            }}
            initialViewState={initialViewState}
            ref={mapRef}
            mapStyle="mapbox://styles/schordr/ckvq4te666xrm14lq6wmj1fi0"
            projection={"globe"}
            mapboxAccessToken={MAPBOX_TOKEN}
            onLoad={onMapLoadMapBox}
            onMoveStart={handleMoveStart}
            onMoveEnd={handleOnMoveEnd}
            style={{ width: "100%", height: "100%" }} // Ensure full size
          >
            <div id="navControlDiv" style={{ padding: 4 }}>
              <NavigationControl style={{ position: "relative", padding: 5 }} />
            </div>

            <div
              id="CreditsDiv"
              style={{
                zIndex: 105,
                width: 200,
                position: "absolute",
                left: 10,
                bottom: 30,
              }}
            >
              <Typography
                component={"div"}
                sx={{ fontSize: 10, color: "white" }}
              >
                {aboveOrBelow() === "below" ? credits : ""}
              </Typography>
            </div>
          </DeckMap>
        </div>
      </div>
    );
  }

  return (
    <>
    <Box
            sx={{
              height: "100%",
              width: '100%',
              // padding: "5px 3px 0 1px",
              // overflow: "auto", // Enables scrolling if content overflows
        
            }}
          >
        <Split
          sizes={[leftWidth, 100 - leftWidth]} // Sizes in percentage
          minSize={[100, 200]} // Minimum sizes in pixels for left and right panes
          gutterSize={10} // Size of the gutter in pixels
          cursor="col-resize" // Cursor style when hovering over the gutter
          direction="horizontal" // Split direction
          onDragEnd={handleResize} // Callback when dragging ends
          className="split" // Custom class for additional styling
        >
          {/* Left Pane: InfoCards */}
          <Box
            sx={{
              height: "100%",
             padding: "5px 5px 0 1px",
              overflow: "auto", // Enables scrolling if content overflows
            }}
          >
            <InfoCards
              filterInputs={filterInputs}
              searchResults={searchResults}
              setSearchResults={setSearchResults}
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
              refs={refs}
              onClick={handleInfoCardClick}
              showMe={true}
              remove={true}
              confirm={confirmIn}
              contactId={contactId}
              clusterData={clusterData && clusterData.features}
              clusterTotal={clusterData && clusterData.totals}
              clusterTotalComps={clusterData && clusterData.totalsComps}
              removeProperty={removeProperty}
              surveyId={surveyId}
              setShowSurveys={setShowSurveys}
              setSaveSurveyOpen={setSaveSurveyOpen}
            />
            {/* <Box sx={{paddingBottom: 100}}></Box> */}
          </Box>

          {/* Right Pane: DeckMap */}

          <Box
            sx={{
              height: "100%",
              width:'100%'
              // padding: "4px 0 0 0",
          
            }}
          >
            {/* Inner Box to allow DeckMap to fill the space */}
 
              <DeckMap
                attributionControl={false}
                onError={(error) => {
                  console.log(error);
                }}
                initialViewState={initialViewState}
                ref={mapRef}
                mapStyle="mapbox://styles/schordr/ckvq4te666xrm14lq6wmj1fi0"
                projection={"globe"}
                mapboxAccessToken={MAPBOX_TOKEN}
                onLoad={onMapLoadMapBox}
                onMoveStart={handleMoveStart}
                onMoveEnd={handleOnMoveEnd}
              
                 style={{ width: "100%", height: '100%',   }} // Ensure full size
              >
                <div id="navControlDiv" style={{ padding: 4 }}>
                  <NavigationControl
                    style={{ position: "relative", padding: 5 }}
                  />
                </div>


                <div
                  id="CreditsDiv"
                  style={{
                    zIndex: 105,
                    width: 200,
                    position: "absolute",
                    left: 10,
                    top: 20,
                  }}
                >
                  <Typography
                    component={"div"}
                    sx={{ fontSize: 10, color: "white" }}
                  >
                    {aboveOrBelow() === "below" ? credits : ""}
                  </Typography>
                </div>
              </DeckMap>
          
          </Box>
        </Split>
        </Box>
    </>
  );
};

export default DeckSplitPanel;
