import { useEffect, useState } from "react";
import axios from "axios";
import Property from "../components/Building/Property.js";
//import BingMapsReact from "bingmaps-react";
import { useSearchParams } from "react-router-dom";

import Header from "../components/Common/Header.js";
// import { Fab } from "@mui/material";
import { useNavigate } from "react-router-dom";
// import Undo from "@mui/icons-material/Undo";







export default function PropertyInfo({ user }) {
  let [query, setQuery] = useSearchParams();
  let id = query.get("id");
 
  const navigate = useNavigate();

  const [loadingPropertyData, setLoadingPropertyData] = useState(true);
  const [loadingPropertyGroupData, setLoadingPropertyGroupData] = useState(true);


  const [propertyDataArray, setPropertyDataArray] = useState({})
  const [propertyGroupData, setPropertyGroupData] = useState({})






  var qs = process.env.REACT_APP_DATABASE + "api/getBuilding?id=" + id + "&contactId=" + user?.contact?.id;
  var qsInvitedTo = process.env.REACT_APP_DATABASE + "api/getGroupsInvitedTo?id=" + user?.contact?.id;


  useEffect(() => { // this will run once and all the loading and data will get filled async

    console.log(qs)
    axios.get(qs).then((propertyDataFetch) => {
       
      setPropertyDataArray(propertyDataFetch.data)
    }).catch((err) => {
      //test - console.log(err);
    }).finally(() => {
      setLoadingPropertyData(false)
    })
    console.log(qsInvitedTo)
    axios.get(qsInvitedTo).then((propertyDataGroupFetch) => {
      
      setPropertyGroupData(propertyDataGroupFetch.data)
    }).catch((err) => {
      //test - console.log(err);
    }).finally(() => {
      setLoadingPropertyGroupData(false)
    })



  }, []);






  if ((loadingPropertyData )) {

    return (
      <>
        <div className="absolute top-1/3 w-full items-center flex flex-col">
          <img style={{width: 100}} src="https://images.cubicsearch.com/Wait.gif" />
          <div>Loading Property Data...</div>
        </div>
      </>
    );

  }
  else if (
    !propertyDataArray ||
    !propertyDataArray.building ||
    propertyDataArray.building.length < 1 ||
    // !peopleDataArray ||
    propertyDataArray.length < 1
  ) {
    return (
      <div>
        <div>
          <Header user={user} />
        </div>
        <div className="py-4">
          <img
            src="https://images.cubicsearch.com/noresults.png"
            className="w-full"
          />
        </div>
      </div>
    );
  }

  // const propertyData = propertyDataArray.building[0];
  const propertyData = propertyDataArray.building;
  propertyData.contacts = propertyDataArray.contacts;
  propertyData.generic_journals = propertyDataArray.journals;
 
  propertyData.avails = propertyDataArray.avails;
  
  const groups = propertyDataArray.groups;
  const campusNames = propertyDataArray.campusNames;
   

  // const people = getContacts(peopleDataArray);

  const pushPin = {
    center: {
      latitude: propertyData.Latitude,
      longitude: propertyData.Longitude,
    },
    options: {
      title:
        propertyData.StreetNumber +
        " " +
        propertyData.StreetName +
        ", " +
        propertyData.City,
    },
  };

  
  return (
    <div>
      <div>
        <Property
          PropertyInformation={propertyData}
          // peopleCombo={people}
          user={user}
          groups={groups}
          groupsCombo={propertyGroupData}
          campusNames = {campusNames}
        />
      </div>
      {/* <div className=" showOnMobile">
        <div className="bottomright">
          <div className="py-1">
            <Fab sx={{ backgroundColor: 'white', color: 'indigo' }} onClick={
              () => {

                return (
                  <>
                    <button onClick={() => navigate(-1)}>Back</button>
                  </>
                );

              }
            }>
              {<Undo />}

            </Fab>
          </div>

        </div>

      </div> */}
    </div>
  );
}

