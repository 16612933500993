// SurveyControls.jsx
import React from "react";
import { Box, Tooltip, Button, Checkbox } from "@mui/material";
import { Favorite, CheckCircle } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import { checkboxClasses } from "@mui/material/Checkbox";
import ClientModalSaveSurvey from "../../Common/ClientProjectSurvey/ClientModalSaveSurvey";
import PrintSurvey from "../../Common/PrintSurvey";
import FilterDialogue from "../../Common/FilterDialogue/FilterDialogue";

const SurveyControls = ({
  user,
  saveSurveyOpen,
  setSaveSurveyOpen,
  searchResults,
  minAvailSize,
  maxAvailSize,
  dateMin,
  dateMax,
  buildingPropertyType,
  availPropertyType,
  availablesCheck,
  setAvailablesCheck,
  compsCheck,
  setCompsCheck,
  allCheck,
  setAllCheck,
  mobileAndTabletCheck,
  surveyId,
  setShowListDialogue,
  setConfirm,
  confirm,
  filters,
  setFilters,
  postFilters,
  initialViewState,
  propertyMapRef,
  showParcels,
  currentViewState,
  setShowParcels,
  printSurvey,
  setPrintSurvey,
  bounds,
  refs,
  mapButton,
  selectedLocation,
  showMarkerPopup,
  showPropertyModal,
  showPrice,
  surveyName,
  surveyNotes,
  setSearchResults,
  setMaxAvailSize,
  setMinAvailSize,
  setAvailPropertyType,
  setBuildingPropertyType,
  filtersClose,
  closeDialogue,
  showFilters,
  setDateMin,
  setDateMax,
  location,
}) => {
  return (

      <Box>
        <div className="stackOnSmall items-center hidden-mobile">
          <div className="flex flex-col w-full">
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                zIndex: 1000,
              }}
            >
              <ClientModalSaveSurvey
                id={user?.contact.id}
                open={saveSurveyOpen}
                setOpen={setSaveSurveyOpen}
                searchResults={searchResults}
                availMin={minAvailSize}
                availMax={maxAvailSize}
                dateMin={dateMin}
                dateMax={dateMax}
                buildingPropertyType={buildingPropertyType}
                availPropertyType={availPropertyType}
                availablesCheck={availablesCheck}
                compsCheck={compsCheck}
                allCheck={allCheck}
              />

              {!mobileAndTabletCheck() ? (
                <>
                  <div>
                    <div
                      style={{ display: "flex", justifyContent: "center" }}
                      className={
                        !mobileAndTabletCheck() ? "py-2 pl-2" : "flex flex-col"
                      }
                    >
                      <div style={{ display: "flex" }}>
                        <div className="pl-2">
                          <Tooltip
                            arrow
                            title={
                              window.location.href.includes("survey")
                                ? "You are on a survey, this will save all current properties as the results for this survey, it will save over previous results"
                                : "This will save all results on the screen to a new survey"
                            }
                          >
                            <Button
                              onClick={() => {
                                if (!surveyId) {
                                  setSaveSurveyOpen(true);
                                } else {
                                  postFilters()
                                  // Assuming saveOverSurveyResultsPromise is defined/imported
                                  // You'll need to pass it as a prop or import it here
                                    .then((result) => console.log(result))
                                    .catch((error) => {
                                      console.log(error);
                                    });
                                }
                              }}
                            >
                              <Favorite />
                              Save All
                            </Button>
                          </Tooltip>
                        </div>
                        {surveyId && (
                          <div className="pl-2">
                            <Tooltip
                              arrow
                              title="This will save all results to a new survey"
                            >
                              <Button
                                className="inline-flex items-center justify-center rounded-md border bg-white px-2 py-2 text-xs font-medium text-red-600 shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-30"
                                onClick={() => {
                                  setSaveSurveyOpen(true);
                                }}
                              >
                                <SaveAsIcon />
                                Save As
                              </Button>
                            </Tooltip>
                          </div>
                        )}
                        {!mobileAndTabletCheck() && (
                          <div className="pl-2">
                            <Tooltip
                              arrow
                              title="List reports for data crunching and reporting"
                            >
                              <Button
                                className="inline-flex items-center justify-start rounded-md border bg-white px-2 py-2 text-xs font-medium text-red-600 shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                                onClick={() => {
                                  setShowListDialogue(true);
                                }}
                              >
                                <MenuIcon />
                                Reports
                              </Button>
                            </Tooltip>
                          </div>
                        )}

                        <div className="pl-2">
                          <Tooltip
                            arrow
                            title="Cubic Search will email the brokers for requests to update or confirm the information"
                          >
                            <Button
                              onClick={() => {
                                setConfirm(!confirm);
                              }}
                            >
                              <CheckCircle />
                              Confirm
                            </Button>
                          </Tooltip>
                        </div>

                        <div className="w-40">
                          <Checkbox
                            sx={{
                              [`&, &.${checkboxClasses.checked}`]: {
                                color: "red",
                              },
                            }}
                            onChange={async (e) => {
                              setAvailablesCheck(e.target.checked);

                              const newFilters = {
                                ...filters,
                                availablesCheck: e.target.checked,
                              };

                              setFilters(newFilters);
                              postFilters(
                                newFilters,
                                searchResults,
                                initialViewState,
                                user.contact.id,
                                setFilters,
                                setSearchResults,
                                propertyMapRef
                              );
                            }}
                            checked={availablesCheck}
                            name="availablesCheck"
                          />{" "}
                          Availables
                        </div>
                        <div className="w-40">
                          <Checkbox
                            sx={{
                              [`&, &.${checkboxClasses.checked}`]: {
                                color: "green",
                              },
                            }}
                            onChange={(e) => {
                              setCompsCheck(e.target.checked);
                              const newFilters = {
                                ...filters,
                                compsCheck: e.target.checked,
                              };

                              setFilters(newFilters);
                              postFilters(
                                newFilters,
                                searchResults,
                                initialViewState,
                                user.contact.id,
                                setFilters,
                                setSearchResults,
                                propertyMapRef
                              );
                            }}
                            checked={compsCheck}
                            name="compsCheck"
                          />{" "}
                          Lease Comps
                        </div>
                        <div className="w-40">
                          <Checkbox
                            sx={{
                              [`&, &.${checkboxClasses.checked}`]: {
                                color: "black",
                              },
                            }}
                            onChange={(e) => {
                              setAllCheck(e.target.checked);
                              const newFilters = {
                                ...filters,
                                allCheck: e.target.checked,
                              };

                              setFilters(newFilters);
                              postFilters(
                                newFilters,
                                searchResults,
                                initialViewState,
                                user.contact.id,
                                setFilters,
                                setSearchResults,
                                propertyMapRef
                              );
                            }}
                            checked={allCheck}
                            name="allCheck"
                          />{" "}
                          All Properties
                        </div>
                        <div className="w-40">
                          <Checkbox
                            checked={showParcels && currentViewState.zoom > 12}
                            sx={{
                              color: "indigo",
                              "&.Mui-checked": {
                                color: "indigo",
                              },
                            }}
                            onChange={() => {
                              if (!showParcels && currentViewState.zoom > 16) {
                                alert(
                                  "Parcels are available in CA, MA, and NC and you need to be zoomed in. Move the map and look for the red lines."
                                );
                              } else if (currentViewState.zoom < 16) {
                                alert("Please zoom in more");
                              }
                              setShowParcels(!showParcels);
                            }}
                          />
                          Show Parcels
                        </div>
                      </div>
                    </div>
                  </div>

                  <PrintSurvey
                    open={printSurvey}
                    setOpen={setPrintSurvey}
                    searchResults={searchResults}
                    setSearchResults={setSearchResults}
                    bounds={bounds}
                    refs={refs}
                    mapButton={mapButton}
                    selectedLocation={selectedLocation}
                    showMarkerPopup={showMarkerPopup}
                    showPropertyModal={showPropertyModal}
                    confirm={confirm}
                    user={user}
                    showPrice={showPrice}
                    surveyName={surveyName}
                    surveyId={surveyId}
                  />
                </>
              ) : <></>}
            </div>

            <FilterDialogue
              postFilters={postFilters}
              filtersClose={filtersClose}
              closeDialogue={closeDialogue}
              availablesCheck={availablesCheck}
              setAvailablesCheck={setAvailablesCheck}
              filters={filters}
              setFilters={setFilters}
              compsCheck={compsCheck}
              setCompsCheck={setCompsCheck}
              allCheck={allCheck}
              setAllCheck={setAllCheck}
              searchResults={searchResults}
              setSearchResults={setSearchResults}
              maxAvailSize={maxAvailSize}
              setMaxAvailSize={setMaxAvailSize}
              minAvailSize={minAvailSize}
              setMinAvailSize={setMinAvailSize}
              availPropertyType={availPropertyType}
              setAvailPropertyType={setAvailPropertyType}
              buildingPropertyType={buildingPropertyType}
              setBuildingPropertyType={setBuildingPropertyType}
              location={location}
              initialViewState={initialViewState}
              user={user}
              propertyMapRef={propertyMapRef}
              showFilters={showFilters}
              dateMin={dateMin}
              setDateMin={setDateMin}
              dateMax={dateMax}
              setDateMax={setDateMax}
            />
          </div>
        </div>
      </Box>
  
  );
};

export default SurveyControls;
