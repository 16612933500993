import React, { useEffect, useState } from "react";
import {
  
  Tooltip,
  Box,
  useTheme,
  useMediaQuery,
  Card,
  Drawer,
} from "@mui/material";

import {
  RemoveCircle as MinusCircleIcon,
  ZoomInOutlined,
} from "@mui/icons-material";
import { getViewportElevation } from "./ElevationFunctions/ElevationsFunctions";
import InfoCard from "./InfoCard";

function PopUp(
  popupInfo,
  setOpen,
  setPopupInfo,
  searchResults,
  setSearchResults,
  refreshMapPropertiesInMain,
  mapRef,
  removeProperty,
  selectedLocation,
  setSelectedLocation,
  setShowSurveys,
  showSurveys,
  surveyId,
  setSaveSurveyOpen
) {
  return (
    <>
      <Card
        sx={{
          borderRadius: 0,
          position: "fixed",
          zIndex: 2000,
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div className="flex w-full  py-2 px-2 flex-col">
          <div className="flex justify-between py-0">
            <Tooltip arrow title="Zoom in">
              <button
                className="w-8 h-8 border-transparent outline-none flex content-center"
                onClick={async (event, info) => {
                  var myViewport = {}; // dummy holder for zoom
                  var elevation = await getViewportElevation(mapRef);
                  if (elevation.elevation > 1000) {
                    myViewport.maxZoom = 14.5;
                  } else if (elevation.elevation > 100) {
                    myViewport.maxZoom = 15;
                  } else {
                    myViewport.maxZoom = 19.5;
                  }
                  mapRef.current.flyTo({
                    center: [popupInfo.Longitude, popupInfo.Latitude],
                    zoom: myViewport.maxZoom,
                    duration: 2000,
                  });
                }}
              >
                <ZoomInOutlined style={{ height: 20, width: 20 }} />
                Zoom In
              </button>
            </Tooltip>
            <Tooltip arrow title="Remove property from search">
              <button
                className="w-7 h-7 border-transparent outline-none flex content-center"
                onClick={(event, info) => {
                  removeProperty(popupInfo);
                  setPopupInfo(false);
                  setOpen(false);
                }}
              >
                <MinusCircleIcon style={{ height: 20, width: 20 }} />
              </button>
            </Tooltip>
          </div>
        </div>
      </Card>
      <div elevation={4} style={{ maxHeight: "100%", overflow: "scroll" }}>
        <div style={{ height: 50 }}></div>
        {/* padding for top */}

        <InfoCard
          key={popupInfo.id}
          property={popupInfo}
          removeProperty={removeProperty}
          selectedLocation={selectedLocation}
          setShowSurveys={setShowSurveys}
          showSurveys={showSurveys}
          setSelectedLocation={setSelectedLocation}
          surveyId={surveyId}
          searchResults={searchResults}
          setSearchResults={setSearchResults}
          mapButton={true}
          showGoogle={true}
          setSaveSurveyOpen={setSaveSurveyOpen}
        />
      </div>
    </>
  );
  {
    /* // </Popup> */
  }
}
export default function SwipeableTemporaryDrawer({
  open,
  setPopupInfo,
  mapButton,
  searchResults,
  setSearchResults,
  setOpen,
  cardProps,
  refreshMapPropertiesInMain,
  mapRef,
  removeProperty,
  selectedLocation,
  setSelectedLocation,
  setShowSurveys,
  showSurveys,
  surveyId,
  setSaveSurveyOpen,
}) {
  const getOrientation = () => {
    if (window.matchMedia("(orientation: portrait)").matches) {
      return "portrait";
    } else if (window.matchMedia("(orientation: landscape)").matches) {
      return "landscape";
    } else {
      return "unknown";
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  // Handle orientation changes
  useEffect(() => {
    const mediaQuery = window.matchMedia("(orientation: portrait)");
    const handleOrientationChange = (e) => {
      setIsPortrait(e.matches);
    };

    mediaQuery.addEventListener("change", handleOrientationChange);
    return () => {
      mediaQuery.removeEventListener("change", handleOrientationChange);
    };
  }, []);
  const [orientation, setOrientation] = useState(getOrientation());

  useEffect(() => {
    const handleOrientationChange = () => {
      setOrientation(getOrientation());
    };

    window.addEventListener("orientationchange", handleOrientationChange);
    window
      .matchMedia("(orientation: portrait)")
      .addListener(handleOrientationChange);
    window
      .matchMedia("(orientation: landscape)")
      .addListener(handleOrientationChange);

    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  if (open && mapButton) {
    return (
      <div
        id={"DrawerParent"}
        style={{
          height:
            orientation === "portrait"
              ? window.innerHeight * 0.4
              : window.innerWidth,
          zIndex: 1,
        }}
      >
        {
          <Drawer
            id={"TheDrawer"}
            anchor={"bottom"}
            open={open}
            onClose={() => {
              setPopupInfo(false);
              setOpen(false);
            }}
            variant="temporary"
          >
            <Box
              id="TheChild"
              sx={{
                height:  isMobile ? (isPortrait ?"50vh" : "70vh") : "50vh", // Adjust height based on orientation and device type
                zIndex: 1,
                overflow: "hidden", // Prevent scrollbars if necessary
                backgroundColor: "background.paper", // Theme-aware background
              }}
            >
              {PopUp(
                cardProps,
                setOpen,
                setPopupInfo,
                searchResults,
                setSearchResults,
                refreshMapPropertiesInMain,
                mapRef,
                removeProperty,
                selectedLocation,
                setSelectedLocation,
                setShowSurveys,
                showSurveys,
                surveyId,
                setSaveSurveyOpen
              )}
            </Box>
          </Drawer>
        }
      </div>
    );
  } else {
    return <></>;
  }
}








