import { ColumnLayer } from '@deck.gl/layers';
import { _TerrainExtension as TerrainExtension } from "@deck.gl/extensions";


export function generateBuildingPostLayer(coordsLines, elevationFactorState, mobileAndTabletCheck) {
 return new ColumnLayer({
    id: "properties-lines",
    data: coordsLines,
    diskResolution: 9,
    radius: mobileAndTabletCheck() ? 1 : 0.5,
    extensions: mobileAndTabletCheck() ? [] : [new TerrainExtension()],
    terrainDrawMode: mobileAndTabletCheck() ? null : "offset",
    extruded: true,
    pickable: false,
    elevationScale: 1,
    getPosition: (d) => d.geometry.coordinates,
    getFillColor: (d) => [255, 255, 255],
    getLineColor: [0, 0, 0],
    getElevation: (d) =>
        mobileAndTabletCheck()
            ? d.mobileElevationHeight - 2
            : 40 * elevationFactorState - 2,
});
}


