import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";

import { useState, useImperativeHandle } from "react";

import React from "react";

import Decky from "./Deck";
import { getViewportElevation } from "./ElevationFunctions/ElevationsFunctions";
import { mobileAndTabletCheck } from "./Deck";
import { Box } from "@mui/material";

const PropertyMap = React.forwardRef(
  (
    {
      fabRefreshRef,
      filterInputs,
      superMapRef,
      setSuperMapRef,
      geometry,
      filters,
      setFilters,
      searchResults,
      setSearchResults,
      bounds,
      selectedLocation,
      onMarkerClick,

      popupInfo,
      setPopupInfo,
      open,
      toggleDrawer,
      hoverInfo,
      setHoverInfo,

      refs,
      setRefs,
      contactId,
      setBoundsRef,
      reloadOnMove,
      elevationFactorState,
      setElevationFactorState,
      setReloadOnMove,
      setAddingNewProperties,
      addingNewProperties,
      showMap,
      showPrice,
      initialViewState,
      setInitialViewState,
      mapReload,
      showParcels,
      showAlert,
      setShowAlert,
      confirmIn,
      mapButton,
      setShowWait,
      setCurrentViewState,
      surveyId,
      setShowSurveys,
      setSelectedLocation,
      setSaveSurveyOpen,
    },
    ref
  ) => {
    // const [popupInfo, setPopupInfo] = useState(null);

    const mapRef = React.useRef(); // this skips to the DeckGL
    const deckyRef = React.useRef(); // this goes to Decky
    const [mapData, setMapData] = useState(null);
    // const [showParcel, setShowParcel] = useState(false);
    // const [loading, setLoading] = useState(true)
    const [geometryJson, setGeometryJson] = useState(null);

    function provideSelectedLocation() {
      return selectedLocation;
    }

    useImperativeHandle(ref, () => ({
      mapReload() {
        //test - console.log("handle called!")
        mapReload();
      },

      async flyTo(coordinates, height) {
        var myViewport = {}; // dummy holder for zoom
        var elevation = await getViewportElevation(mapRef);

        if (elevation.elevation > 1000) {
          myViewport.maxZoom = 14.5;
        } else if (elevation.elevation > 100) {
          myViewport.maxZoom = 15;
        } else {
          myViewport.maxZoom = 19.5;
        }

        mapRef.current.flyTo({
          center: [coordinates.longitude, coordinates.latitude],
          zoom: myViewport.maxZoom - height / 200,
          duration: 2000,
        });
      },

      refreshMapProperties() {
        //test - console.log("handle called!")
        refreshMapProperties();
      },

      removeProperty(property) {
        deckyRef.current.removeProperty(property);
      },

      redoIcons(selectedLocation) {
        deckyRef?.current?.redoIcons(selectedLocation);
      },

      setSearchedLocations(coordinates) {
        //test - console.log("setSearchedLocations called")

        setSearchedLocations(coordinates);
      },
    }));

    function refreshMapProperties() {
      //test - console.log("local mapreload called")
      // deckyRef.current.addViewStates()
      deckyRef.current.refreshMapProperties();
    }
    function mapReload() {
      //test - console.log("local mapreload called")
      // deckyRef.current.addViewStates()
      deckyRef.current.mapReload();
    }

    function setSearchedLocations(coordinates) {
      if (deckyRef && deckyRef.current) {
        deckyRef.current.setSearchedLocations(coordinates);
      }
    }

  

    if ((addingNewProperties || geometry) && showMap && mapRef?.current) {
      if (
        geometry &&
        (!geometryJson ||
          (geometry.location &&
            geometry.location.lat !== geometryJson.location.lat &&
            geometry.location.lng !== geometryJson.location.lng))
      ) {
        if (geometry.location) {
          setGeometryJson(geometry);
        } else {
          setGeometryJson(JSON.parse(geometry));
        }
      }

      if (addingNewProperties) {
        setAddingNewProperties(false);
      }
    }

    if (showMap && initialViewState && initialViewState.latitude) {
      return (
        <>
          <Box
            id="deckydiv"
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
          
              
            }}
          >
            <Decky
              fabRefreshRef={fabRefreshRef}
              filterInputs={filterInputs}
              ref={deckyRef}
              geometry={geometry}
              superMapRef={superMapRef}
              setSuperMapRef={setSuperMapRef}
              bounds={bounds}
              mapRef={mapRef}
              setMapData={setMapData}
              searchResults={searchResults}
              setSearchResults={setSearchResults}
              refs={refs}
              setRefs={setRefs}
              contactId={contactId}
              setBoundsRef={setBoundsRef}
              reloadOnMove={reloadOnMove}
              elevationFactorState={elevationFactorState}
              setElevationFactorState={setElevationFactorState}
              setReloadOnMove={setReloadOnMove}
              mapData={mapData}
              sortByProperty={sortByProperty}
              // pins={pins}
              onMarkerClick={onMarkerClick}
              hoverInfo={hoverInfo}
              setHoverInfo={setHoverInfo}
              popupInfo={popupInfo}
              setPopupInfo={setPopupInfo}
              open={open}
              toggleDrawer={toggleDrawer}
              initialViewState={initialViewState}
              setInitialViewState={setInitialViewState}
              mapButton={mapButton}
              filters={filters}
              setFilters={setFilters}
              showPrice={showPrice}
              showParcels={showParcels}
              showAlert={showAlert}
              setShowAlert={setShowAlert}
              confirmIn={confirmIn}
              setShowWait={setShowWait}
              setCurrentViewState={setCurrentViewState}
              surveyId={surveyId}
              setShowSurveys={setShowSurveys}
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
              getSelectedLocation={provideSelectedLocation}
              setSaveSurveyOpen={setSaveSurveyOpen}
            />
          </Box>
        </>
      );
    }
  }
);
export default PropertyMap;
export function showData(result) {
  var availsOnly = result.avails
    ? result.avails.filter((avail) => {
        if (avail.Status === "Lease Comp") {
          return false;
        } else return true;
      })
    : false;

  var compsOnly = result.avails
    ? result.avails.filter((avail) => {
        if (avail.Status === "Lease Comp") {
          return true;
        } else return false;
      })
    : false;
  return (
    <div>
      {availsOnly && availsOnly.length > 0 ? (
        <div className="text-sm">Available Spaces</div>
      ) : (
        ""
      )}
      {availsOnly && availsOnly.length > 0
        ? availsOnly
            .sort((a, b) => a.Floor - b.Floor)
            .map((available) => {
              if (available.Status !== "Lease Comp") {
                return AvailData(available);
              } else {
                return CompData(available);
              }
            })
        : ""}
      {compsOnly && compsOnly.length > 0 ? (
        <div className="text-sm">Lease Transactions</div>
      ) : (
        ""
      )}
      {compsOnly
        ? compsOnly
            .sort((a, b) => a.Floor - b.Floor)
            .map((available) => {
              if (available.Status !== "Lease Comp") {
                return AvailData(available);
              } else {
                return CompData(available);
              }
            })
        : ""}
    </div>
  );
}

// const removeById = (arr, id) => {
//   const requiredIndex = arr.findIndex((el) => {
//     return el.id === id;
//   });

//   if (requiredIndex === -1) {
//     return false;
//   }
//   arr.splice(requiredIndex, 1);
//   return arr;
// };
function sortByProperty(property) {
  return function (b, a) {
    if (a[property] > b[property]) return 1;
    else if (a[property] < b[property]) return -1;

    return 0;
  };
}

var nfObject = new Intl.NumberFormat("en-US");
var nfObjectRate = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
// var nfObjectPercent = new Intl.NumberFormat("en-US", { style: "percent" });

function AvailData(available) {
  return (
    <div
      key={"avail" + available.id}
      className="px-2 py-1  hover:outline-red text-xs"
      onClick={() => {
        window.open(
          "available?id=" + available.id,
          mobileAndTabletCheck() ? "_self" : null
        );
      }}
    >
      {nfObject.format(available.SizeMin)} -{" "}
      {nfObject.format(available.SizeMax)} SF{" "}
      {available.Floor > 0 ? " | Floor: " + available.Floor : ""}{" "}
      {available.SubleaseOrDirect ? " | " + available.SubleaseOrDirect : ""}
      {available.DateOccupancy
        ? " | Occupancy: " +
          new Date(available.DateOccupancy).toLocaleDateString()
        : ""}
      {available.Condition ? " | " + available.Condition : ""}
      {available.AskRate
        ? " | " +
          nfObjectRate.format(available.AskRate) +
          " " +
          available.RentStructure
        : ""}
    </div>
  );
}

function CompData(available) {
  return (
    <div
      key={"comp" + available.id}
      className="px-2 py-1 hover:outline-red text-xs"
      onClick={() => {
        window.open(
          "available?id=" + available.id,
          mobileAndTabletCheck() ? "_self" : null
        );
      }}
    >
      {available.Tenant} | {nfObject.format(available.LeasedSpace)} SF{" "}
      {available.Floor > 0 ? " | Floor: " + available.Floor : ""}{" "}
      {available.SubleaseOrDirect ? " | " + available.SubleaseOrDirect : ""}
      {available.ExecutionDate
        ? " | Signed: " + new Date(available.ExecutionDate).toLocaleDateString()
        : ""}
      {available.Condition ? " | " + available.Condition : ""}
      {available.InitialRent
        ? " | " +
          nfObjectRate.format(available.InitialRent) +
          " " +
          available.RentStructure
        : ""}
    </div>
  );
}
