/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react";
import { Dialog, DialogActions, DialogContent, Button } from "@mui/material";

import { LicenseInfo } from "@mui/x-license-pro";

import { Box } from "@mui/material";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { WindowSharp } from "@mui/icons-material";


LicenseInfo.setLicenseKey(
    process.env.REACT_APP_MUI
);

export default function AvailableGrid({ open, setOpen, dataIn }) {
    const cancelButtonRef = useRef(null);
    const gridRef = useRef(null);
    const [selectedRows, setSelectedRows] = useState(null);
    var availSF = 0;
    var totalSF = 0;
    const rows = [];
    var nfObjectRate = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });
    var nfObject = new Intl.NumberFormat("en-US");




    if (dataIn && dataIn.length > 0) {

        var i = 0
        dataIn.map((data) => {
            if (data.avails) {
                data.avails.map((avail) => {
                    var editable = false;
                    // 
                    data.groups ?
                        data.groups.map((group) => {
                            if (
                                group?.building_group?.groupPermission === "Edit" ||
                                group?.building_group?.groupPermission === "Edit"
                            ) {
                                editable = true;
                            }

                        }) : editable = false

                    var index = rows.indexOf((row) => {
                        return avail.id === row.availId;
                    });

                    if (index >= 0) {
                        //alert(index);
                    }
                    if ((index < 0) && (avail.Status !== "Lease Comp")) {

                        rows.push({
                            id: i + 1,
                            availId: avail.id,

                            YearBuilt: data.YearBuilt,
                            // surveyUpdatedAt: new Date(data.survey.updatedAt),
                            StreetNumber: data.StreetNumber,
                            StreetName: data.StreetName,
                            PropertyType: data.PropertyType,
                            CampusName: data.CampusName,
                            PropertyName: data.PropertyName,
                            City: data.City,
                            State: data.State,
                            Size: data.Size,
                            Editable: editable,
                            totalAvail: data.totalAvail,

                            YearRenovated: data.YearRenovated,
                            NumberFloors: data.NumberFloors,
                            FloorTofloor: data.FloorToFloor,
                            BackupGenerator: data.BackupGenerator,
                            PowerAmps: data.PowerAmps,
                            PowerVolts: data.PowerVolts,
                            UtilityPad: data.UtilityPad,
                            ParkingRatio: data.ParkingRatio,
                            Notes: data.Notes,

                            SizeMin: avail.SizeMin,
                            SizeMax: avail.SizeMax,
                            DateOccupancy: new Date(avail.DateOccupancy),
                            Floor: avail?.Floor === -1 ? 'All' : avail?.Floor,
                            AskRate: avail.AskRate,
                            RentStructure: avail.RentStructure,
                            SpaceType: avail.PropertyType,

                            SubleaseOrDirect: avail.SubleaseOrDirect,
                            // permission: data.groupPermission,
                        });
                        i++

                        availSF = availSF + parseInt(avail.SizeMax);

                        // totalSF = totalSF + data.Size && parseInt(data.Size);
                    }
                })
            }

        });
    }

    const valueFormatter = (params) => {


        if (!params || typeof params === 'undefined' || params === null) {
            return '';
        }

        return nfObject.format(Number(params));
    };

    const askRateFormatter = (params) => {

        if (!params || typeof params === 'undefined' || params === null) {
            return '';
        }

        return nfObjectRate.format(Number(params));
    };



    const columns = [
        {
            field: "SizeMin",
            headerName: "Min Avail SF",
            width: 150,
            editable: (params) => params.row.editable,
            valueFormatter: valueFormatter,
            resizable: true,
        },
        {
            field: "SizeMax",
            headerName: "Max Avail SF",
            width: 150,
            editable: (params) => params.row.editable,
            valueFormatter: valueFormatter,
            resizable: true,
        },
        {
            field: "DateOccupancy",
            headerName: "Date Occupancy",
            width: 150,
            editable: (params) => params.row.editable,
            type: "date",
            resizable: true,
        },
        {
            field: "Floor",
            headerName: "Floor",
            width: 100,
            editable: (params) => params.row.editable,
            resizable: true,
        },
        {
            field: "AskRate",
            headerName: "Ask Rent/SF/Month",
            width: 100,
            editable: (params) => params?.row?.editable,
            type: "number",
            valueFormatter: askRateFormatter,
            resizable: true,
        },
        {
            field: "RentStructure",
            headerName: "Rent Structure",
            width: 100,
            editable: (params) => params.row.editable,
            resizable: true,
        },
        {
            field: "SpaceType",
            headerName: "Space Type",
            width: 100,
            editable: (params) => params.row.editable,
            resizable: true,
        },
        {
            field: "SubleaseOrDirect",
            headerName: "Sublease/Direct",
            width: 100,
            editable: (params) => params.row.editable,
            resizable: true,
        },
        {
            field: "StreetNumber",
            headerName: "Street #",
            width: 100,
            editable: (params) => params.row.editable,
            resizable: true,
            renderCell: (params) => (
                <a
                    href={"avail?id=" + params.row.availId}
                    target="_blank"
                    className="text-blue cursor-pointer underline"
                >
                    {params.row.StreetNumber}
                </a>
            ),
        },
        {
            field: "StreetName",
            headerName: "Street",
            width: 300,
            editable: (params) => params.row.editable,
            renderCell: (params) => (
                <a
                    href={"property?id=" + params.row.propertyId}
                    target="_blank"
                    className="text-blue cursor-pointer underline"
                >
                    {params.row.StreetName}
                </a>
            ),
        },
        {
            field: "City",
            headerName: "City",
            width: 100,
            editable: (params) => params.row.editable,
        },
        {
            field: "PropertyType",
            headerName: "Property Type",
            width: 200,
            editable: (params) => params.row.editable,
            type: "singleSelect",
            valueOptions: [
                "Life Science",
                "Life Science or Industrial",
                "Life Science or Office",
                "Life Science, R&D, or Office",
                "Life Science, R&D, or Industrial",
                "GMP",
                "R&D",
                "R&D or Office",
                "Office",
                "Industrial",
                "Retail",
                "Other",
            ],
        },
        {
            field: "YearBuilt",
            headerName: "Year Built",
            width: 150,
            editable: (params) => params.row.editable,
            resizable: true,
        },
        {
            field: "CampusName",
            headerName: "Campus",
            width: 200,
            editable: (params) => params.row.editable,
        },
        {
            field: "PropertyName",
            headerName: "Building Name",
            width: 200,
            editable: (params) => params.row.editable,
        },
        {
            field: "Size",
            headerName: "Building Size",
            width: 100,
            editable: (params) => params.row.editable,
            type: "number",
        },
        {
            field: "NumberFloors",
            headerName: "Number of Floors",
            width: 100,
            type: "number",
        },
        {
            field: "FloorTofloor",
            headerName: "Clear Height",
            width: 100,
            type: "number",
        },
        {
            field: "BackupGenerator",
            headerName: "Backup Generator",
            width: 100,
            type: "number",
        },
        {
            field: "PowerAmps",
            headerName: "Power (Amps)",
            width: 100,
            type: "number",
        },
        {
            field: "PowerVolts",
            headerName: "Power (Volts)",
            width: 100,
            type: "number",
        },
        {
            field: "UtilityPad",
            headerName: "Utility Pad",
            width: 100,
            type: "text",
        },
        {
            field: "ParkingRatio",
            headerName: "Parking Ratio (Spots/1000SF)",
            width: 100,
            type: "number",
        },
        {
            field: "Notes",
            headerName: "Building Notes",
            width: 100,
            type: "text",
        },
    ];


    return (
        <>
            <Dialog maxWidth={''}
                // PaperProps={{
                //     sx: {

                //         height: window.screen.height * .8
                //     }
                // }}
                open={open} onClose={() => {
                    setOpen(false)
                }} title=" Available Space List">
                <DialogContent>
                    <Box sx={{ height: window.screen.height * .8, width: "100%" }}>
                        <DataGridPro
                            slots={{ toolbar: GridToolbar }}
                            initialState={{
                                sorting: {
                                    sortModel: [
                                        { field: "City", sort: "asc" },
                                        { field: "StreetName", sort: "asc" },
                                        { field: "StreetNumber", sort: "asc" },
                                    ],
                                },
                            }}
                            ref={gridRef}
                            rows={rows}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            // checkboxSelection
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                            isRowSelectable={(params) => {
                                return true;
                                // params.row.permission === "Edit" ||
                                // params.row.permission === "Lead Edit"
                            }}
                            onRowSelectionModelChange={(ids) => {
                                const selectedIDs = new Set(ids);
                                const selectedRowData = rows.filter((row) => {
                                    return selectedIDs.has(row.id);
                                });
                                setSelectedRows(selectedRowData);
                            }}
                        />
                    </Box>

                    <div>
                        Total Availble Space:{" "}
                        {availSF.toLocaleString()}
                    </div>

                </DialogContent>
                <DialogActions><Button onClick={() => { setOpen(false) }} >OK</Button></DialogActions>
            </Dialog>
        </>





    );
}
